import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Col, Row } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Tabs, Button } from "antd";
import AWSConectorAPIHelper from "../../../API/awsConectorAPIHelper";
import AwsSummaryAdvisor from "../../../API/AwsSummaryAdvisor";
import ChatIcon from "../../../assets/images/chat.svg";
import "./SummaryAdvisor.scss";
const { TabPane } = Tabs;


// const columns = [
//  {
//   title: "Check",
//   dataIndex: "data",
//   key: "data",
//   render: (text) => text[0],
//  },
//  {
//   title: "Status",
//   dataIndex: "data",
//   key: "data",
//   render: (text) => (
//    <Tag
//     color={
//      (text[1] === "ok" && "#87d068") ||
//      (text[1] === "warning" && "#ffff00") ||
//      (text[1] === "error" && "#ff0000b8")
//     }
//     key={text}
//    >
//     <span style={{ fontSize: "14px", color: "black" }}>{text[1]}</span>
//    </Tag>
//   ),
//  },
//  {
//   title: "Resources Processed",
//   dataIndex: "data",
//   key: "data",
//   render: (text) => text[2],
//  },
//  {
//   title: "Resources Flagged",
//   key: "data",
//   dataIndex: "data",
//   render: (text) => text[3],
//  },
//  {
//   title: "Resources Suppressed",
//   key: "data",
//   dataIndex: "data",
//   render: (text) => text[4],
//  },
//  {
//   title: "Resources Ignored",
//   key: "data",
//   dataIndex: "data",
//   render: (text) => text[5],
//  },
// ];

const objTextChange = {
 fault_tolerance: "Fault Tolerance",
 security: "Security",
 service_limits: "Service Limits",
 cost_optimizing: "Cost Optimizing",
 performance: "Performance",
};
export default class SummaryAdvisor extends Component {
 constructor(props) {
  super(props);

  this.state = {
   connectorList: [],
   costTypeList: ["Cost Type"],
   connectorId: "-1",
   costType: "",
   AWSSummaryData: {},
   AWSSummaryKeys: [
    "fault_tolerance",
    "security",
    "service_limits",
    "cost_optimizing",
    "performance",
   ],
  };
 }
 componentDidMount() {
  Auth.currentAuthenticatedUser({
   bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
   .then((user) => {
    if (!user) this.props.history.push("/");
    else {
     console.log("User is logged-in");
     this.props.userHasAuthenticated(true);
     this.getConntectors();
    }
   })
   .catch((err) => {
    this.props.userHasAuthenticated(false);
    this.props.history.push("/");
   });
 }

 getConntectors = async () => {
  this.props.setLoadingWheel(true);
  const connectors = await AWSConectorAPIHelper.getRoleArnList();
  this.setState({
   connectorList: connectors.data.body.map((x) => x[1].stringValue),
  });
  this.props.setLoadingWheel(false);
 };

 getSummaryData = async () => {
  if (!this.state.connectorId || this.state.connectorId === "") {
   alert("Please Select Connector");
   return;
  }
  this.props.setLoadingWheel(true);
  const SummaryData = await AwsSummaryAdvisor.getCloudAdvisor(
   this.state.connectorId
  );
  if (SummaryData.status === 200) {
   console.log(SummaryData, "SummaryData");
   this.setState({ AWSSummaryData: SummaryData.data.body });
   this.props.setLoadingWheel(false);
  } else {
   alert(SummaryData.data.errorMessage);
   this.props.setLoadingWheel(false);
  }
 };

 getCountsOfStatus = (keyName) => {
  let error = 0;
  let warning = 0;
  this.state.AWSSummaryData[keyName].forEach((obj) => {
   if (obj[1] === "error") {
    error += 1;
   }
   if (obj[1] === "warning") {
    warning += 1;
   }
  });
  return { error, warning };
 };

 onConnectorChange = (event) => {
  this.setState({ connectorId: event.target.value });
 };
 render() {
  return (
   <div className="SA-wrapper">
    <div className=" SATopChat">
     <img src={ChatIcon} alt="chat" className="SAChatIcon" />
     <span className="my-0 SAChatPara">
      Cloud Advisor requires AWS Business Support as a prerequisite to be
      enabled in your AWS Account.
     </span>
    </div>
    <div>
     <Row>
      <Col span={4}>
       <Row>
        <div
         style={{
          width: "85%",
          padding: "5px",
          backgroundColor: "#ffffff",
          borderRadius: 8,
         }}
        >
         <select
          className="select-dash"
          defaultValue="AWS"
          disabled
          style={{
           width: "100%",
           backgroundColor: "#ffffff",
           border: "0px",
           color: "#000000",
           padding: "5px",
           borderColor: "#ffffff",
          }}
         >
          <option value="AWS">AWS</option>
         </select>
        </div>
       </Row>
      </Col>
      <Col span={8}>
       <Row>
        <div
         style={{
          width: "85%",
          padding: "5px",
          backgroundColor: "#ffffff",
          borderRadius: 8,
         }}
        >
         <select
          className="select-dash"
          value={this.state.connectorId}
          onChange={this.onConnectorChange}
          style={{
           width: "100%",
           backgroundColor: "#ffffff",
           border: "0px",
           color: "#000000",
           padding: "5px",
           borderColor: "#ffffff",
          }}
         >
          <option key={"select Connector Name"} value={"select Connector Name"}>
           Select Connector Name
          </option>

          {this.state.connectorList.map((account) => {
           return (
            <option key={account} value={account}>
             {account}
            </option>
           );
          })}
         </select>
        </div>
       </Row>
      </Col>
      <Col span={4}>
       <Button
        type="primary"
        onClick={this.getSummaryData}
        shape="round"
        size={"large"}
       >
        AWS Cloud Environment Scan
       </Button>
      </Col>
     </Row>
    </div>
    <br />
    <br />
    <div>
     <div>
      <div className="row d-flex justify-content-between ">
       {}
       {Object.keys(this.state.AWSSummaryData).map((j, i) => {
        const obj = this.getCountsOfStatus(this.state.AWSSummaryKeys[i]);
        console.log("box headings", j);
        return (
         <div className="col-12 col-sm-6 col-xl-2 col-md-4 px-0 mx-0 mb-3 d-flex flex-column justify-content-between">
          <span className="SATopBoxHeader">
           {objTextChange?.[this.state.AWSSummaryKeys[i] || 0]} Services
          </span>
          <div className="SATopBox">
           {obj && (
            <span className="SATopBoxHeading SATopBoxHeading1">
             Total Warnings: {obj.warning}
            </span>
           )}
           <br />
           &nbsp;&nbsp;
           {obj && (
            <span className="SATopBoxHeading SATopBoxHeading2">
             Total Errors: {obj.error}
            </span>
           )}
          </div>
         </div>
        );
       })}
      </div>
     </div>
     <Tabs
      tabBarStyle={{ textAlign: "left !important" }}
      defaultActiveKey="1"
      style={{ paddingTop: "70px" }}
      tabPosition={"left"}
      className="SATableTab"
     >
      {this.state.AWSSummaryKeys.map((table) => (
       <TabPane tab={objTextChange[table]} key={table}>
        {/*  <Table
         pagination={false}
         columns={columns}
         dataSource={this.state.AWSSummaryData[table]?.map((x) =>{ 
          console.log("object we need to check",x);
          return ({
          data: x,
         })})}
        />*/}
        {this.state.AWSSummaryData[table]?.length > 0 && (
         <div className="SATableContainer">
          <table class="table table-striped">
           <thead>
            <tr>
             <th scope="col" className="SATableHeading ">
              Check
             </th>
             <th scope="col" className="SATableHeading text-center">
              Status
             </th>
             <th scope="col" className="SATableHeading text-center">
              Resources Processed
             </th>
             <th scope="col" className="SATableHeading text-center">
              Resources Flagged
             </th>
             <th scope="col" className="SATableHeading text-center">
              Resources Suppressed
             </th>
             <th scope="col" className="SATableHeading text-center">
              Resources Ignored
             </th>
            </tr>
           </thead>
           <tbody className="SATableBody">
            {this.state.AWSSummaryData[table]?.map((table) => (
             <tr>
              <td className="SATableDataCheck">{table?.[0]}</td>
              <td
               className="SATableDataStatus"
               style={
                table?.[1] === "ok"
                 ? { color: "#538DB1", textTransform: "uppercase" }
                 : { color: "#D11915", textTransform: "uppercase" }
               }
              >
               {table?.[1]}
              </td>
              <td
               className="SATableData"
               style={
                table?.[2] !== "0" && table?.[1] === "warning"
                 ? { color: "#D11915" }
                 : {}
               }
              >
               {table?.[2]}
              </td>
              <td
               className="SATableData"
               style={
                table?.[3] !== "0" && table?.[1] === "warning"
                 ? { color: "#D11915" }
                 : {}
               }
              >
               {table?.[3]}
              </td>
              <td
               className="SATableData"
               style={
                table?.[4] !== "0" && table?.[1] === "warning"
                 ? { color: "#D11915" }
                 : {}
               }
              >
               {table?.[4]}
              </td>
              <td
               className="SATableData"
               style={
                table?.[5] !== "0" && table?.[1] === "warning"
                 ? { color: "#D11915" }
                 : {}
               }
              >
               {table?.[5]}
              </td>
             </tr>
            ))}
           </tbody>
          </table>
         </div>
        )}
       </TabPane>
      ))}
     </Tabs>
    </div>
   </div>
  );
 }
}
