import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { Logger } from "@aws-amplify/core";
//import { BackwardFilled } from '@ant-design/icons';
import { Col, Row } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Button } from 'antd';
import { Table } from 'antd';
import { Route, Link } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import { Spin } from 'antd';
import axios from 'axios';
import { RecentActionsTable } from './recentActionsTable';

const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};


const columns = [
    {
        title: 'Instance ID',
        dataIndex: 'Instance ID',
        key: 'Instance ID',
    },
    {
        title: 'Instance type',
        dataIndex: 'Instance type',
        key: 'Instance type',
    },
    {
        title: 'Region',
        dataIndex: 'Region',
        key: 'Region',
    },
    {
        title: 'Instance Image',
        dataIndex: 'Instance Image',
        key: 'Instance Image',
    },
    {
        title: 'Connector Name',
        dataIndex: 'Connector Name',
        key: 'Connector Name',
    },
    {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
    },
    {
        title: 'Last status',
        dataIndex: 'Last status',
        key: 'Last status',
    }
];
const Ec2 = (props) => {
    const logger = new Logger("Ec2");
    const [display, setDisplay] = useState(false)
    const [search, setSearch] = useState('');
    const [searchItem, setSearchItem] = useState('');
    const [connectorData, setConnectorData] = useState([])
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const saveAllData = useRef([]);

    const [displayRegion, setDisplayRegion] = useState(false)
    const [searchRegion, setSearchRegion] = useState('');
    const [searchItemRegion, setSearchItemRegion] = useState('');
    const [regionData, setRegionData] = useState([])
    const inputRefRegion = useRef(null);
    const saveAllDataRegion = useRef([]);
    const history = useHistory();
    const [data, setData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [error, setErrorMessage] = useState('')
    const [success, setSuccessMessage] = useState('')
    const [form] = Form.useForm();


    const onFinish = (values) => {
        setSpinner(true)
        axios.post('https://1srfd17xp0.execute-api.eu-west-1.amazonaws.com/Prod/ec2manage', values)
            .then(data => {
                setSuccessMessage('Action Successful')
                // console.log('Success:', data);
                setSpinner(false)
                let resError = data.errorMessage;
                if (resError) {
                    setErrorMessage(resError)
                }

                // history.push('/rds')
                form.resetFields();
                form.setFieldsValue({ useremail: localStorage.getItem('email') });
            })
            .catch((error) => {
                console.error('Error:', error);
                setSpinner(false)
            });
    };

    const onReset = () => {
        form.resetFields();
        form.setFieldsValue({ useremail: localStorage.getItem('email') });
    };


    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                history.push("/")
            else {
                logger.log("User is logged-in");
                props.userHasAuthenticated(true);
            }
        }).catch(err => {
            logger.log("Couldn't get the logged-in user for some reason: " + err);
            props.userHasAuthenticated(false);
            history.push("/")
        }
        );

    })
    useEffect(() => {
        axios.get('https://70s2mnezlh.execute-api.eu-west-1.amazonaws.com/Prod/getec2instnaces', {
            params: {
                authuseremail: localStorage.getItem('email'),
                datemonth: '2021-02-28',
                accountname: 'dev3',

            }

        })
            .then(data => {
                let modifyData = data.data.body.map((element, index) => {
                    element['Instance ID'] = element[0].stringValue;
                    element['Instance type'] = element[1].stringValue;
                    element['Instance Image'] = element[2].stringValue;
                    element['Instance 1'] = element[3].stringValue;
                    element['Last status'] = element[4].stringValue;
                    element['Region'] = element[5].stringValue;
                    element['Date'] = element[6].stringValue;
                    element['Connector Name'] = element[7].stringValue;
                    element['Email'] = element[8].stringValue;
                    return element;
                })
                // let emailFiltered = modifyData.filter(element => {
                //     return element['Email'] === localStorage.getItem('email')
                // })
                saveAllData.current = modifyData;
                saveAllDataRegion.current = modifyData;
                setData(modifyData);
                setConnectorData(modifyData);
                setRegionData(modifyData)
            })

        form.setFieldsValue({ useremail: localStorage.getItem('email') })
    }, [form])
    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    });
    // useEffect(() => {
    //     let filter = saveAllData.current.filter(eachElement => eachElement['Connector Name'] === searchItem && eachElement['Region'] === searchItemRegion)
    //     console.log(filter, "filter")
    //     setData(filter)

    // }, [search, searchRegion]);
    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            setDisplay(false);
            setDisplayRegion(false);
        }
    };
    const textChange = (e) => {
        e.preventDefault();
        setDisplay(true);
        let searchIndividual = e.target.value;
        setSearch(searchIndividual)
        setSearchItem(searchIndividual);
    }
    const textChangeRegion = (e) => {
        e.preventDefault();
        setDisplayRegion(true);
        let searchIndividual = e.target.value;
        setSearchRegion(searchIndividual)
        setSearchItemRegion(searchIndividual);
    }
    const updateTextField = (element) => {


        // console.log(element['Connector Name'], [...saveAllData.current]);
        setData([...saveAllData.current])
        setSearch(element['Connector Name'])
        setSearchRegion('')
        let filter = saveAllData.current.filter(eachElement => eachElement['Connector Name'] === element['Connector Name'])
        // console.log(filter);
        setData(filter)
        setDisplay(false);
    }
    const updateTextFieldRegion = (element) => {


        // console.log(element['Region'], [...saveAllData.current]);
        setData([...saveAllData.current])
        setSearchRegion(element['Region'])
        setSearch('')
        let filter = saveAllDataRegion.current.filter(eachElement => eachElement['Region'] === element['Region'])
        // console.log(filter);
        setData(filter)
        setDisplayRegion(false);
    }
    const handleCallback = (start, end, label) => {
        // console.log("Handle Callback", start._d, end._d, label);
        setData([...saveAllData.current])
        let startDate = new Date(start._d).toISOString().split('T')[0];
        let endDate = new Date(end._d).toISOString().split('T')[0];
        // console.log(startDate, endDate);
        let result = data.filter(d => {
            let date = new Date(d['Date']).toISOString().split('T')[0];
            // console.log(date);
            return (startDate < date && date < endDate);
        });
        // console.log(result, "Date");
        setData(result)
    }

    return (
        <>

            <Route exact path={'/ec2'}>
                <div ref={wrapperRef} >
                    <Row style={{ background: "#3386ff" }}>
                        <Col span={8}></Col>

                        <Col span={4}>
                            <Row>
                                <div style={{ width: '75%', padding: '5px', backgroundColor: '#3386ff' }}>
                                    <input
                                        id="inputConnector"
                                        style={{
                                            width: '100%', padding: '5px', backgroundColor: '#3386ff', color: "white",
                                            ':focus': {
                                                color: "#fff"
                                            },
                                            border: "1px solid white",
                                            fontSize: '16px', borderColor: 'white'
                                        }}
                                        onClick={() => {
                                            setDisplay(!display)

                                        }} ref={inputRef} value={search} placeholder="Select Connector Name" type="text" onChange={textChange} />
                                    {display && <div style={{
                                        position: "absolute", fontSize: "16px",
                                        top: '85%', zIndex: 1, background: "white", listStyle: "none", borderRadius: 10, boxShadow: "13px 10px 13px -9px rgba(0,0,0,0.66)",
                                        cursor: 'pointer', width: '75%', padding: 10, maxHeight: '250px', overflow: "auto", scrollBehavior: "smooth"
                                    }}>
                                        {connectorData.filter((name) => name['Connector Name'].indexOf((searchItem)) > -1).map((element, index) => {
                                            return <div onClick={() => updateTextField(element)} key={index} style={{ fontWeight: "bold", display: "flex", marginTop: 2, marginBottom: 4 }} tabIndex="0">  <li style={{ marginLeft: 3 }}>{element['Connector Name']}</li></div>
                                        })}
                                    </div>}
                                    {/* <select style={{ width: '100%', backgroundColor: '#3386ff', color: 'white', padding: '5px', borderColor: 'white' }}>


                                        <option >Connector Name</option>



                                    </select> */}
                                </div>
                            </Row>
                        </Col>
                        <Col span={4}>
                            <Row>
                                <div style={{ width: '75%', backgroundColor: '#3386ff' }}>
                                    <div style={{ width: '75%', padding: '5px', backgroundColor: '#3386ff' }}>
                                        <input
                                            id="inputRegion"
                                            style={{
                                                width: '100%', backgroundColor: '#3386ff', color: "white",
                                                ':focus': {
                                                    color: "#fff"
                                                },
                                                border: "1px solid white",
                                                fontSize: '16px', padding: '5px', borderColor: 'white'
                                            }}
                                            onClick={() => {
                                                setDisplayRegion(!displayRegion)

                                            }} ref={inputRefRegion} value={searchRegion} placeholder="Select Region" type="text" onChange={textChangeRegion} />
                                        {displayRegion && <div style={{
                                            position: "absolute", fontSize: "16px",
                                            top: '85%', zIndex: 1, background: "white", listStyle: "none", borderRadius: 10, boxShadow: "13px 10px 13px -9px rgba(0,0,0,0.66)",
                                            cursor: 'pointer', width: '75%', padding: 10, maxHeight: '250px', overflow: "auto", scrollBehavior: "smooth"
                                        }}>
                                            {regionData.filter((name) => name['Region'].indexOf((searchItemRegion)) > -1).map((element, index) => {
                                                return <div onClick={() => updateTextFieldRegion(element)} key={index} style={{ fontWeight: "bold", display: "flex", marginTop: 2, marginBottom: 4 }} tabIndex="0">  <li style={{ marginLeft: 3 }}>{element['Region']}</li></div>
                                            })}
                                        </div>}
                                        {/* <select style={{ width: '100%', backgroundColor: '#3386ff', color: 'white', padding: '5px', borderColor: 'white' }}>


                                        <option >Connector Name</option>



                                    </select> */}
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        <Col span={4}>
                            <Row>
                                <div style={{ width: '75%', padding: '5px', backgroundColor: '#3386ff' }}>
                                    <DateRangePicker onCallback={handleCallback}
                                        initialSettings={{ startDate: new Date(), endDate: new Date() }}
                                    >
                                        <input type="text" className="form-control" style={{ width: '100%', backgroundColor: '#3386ff', color: 'white', padding: '15px', borderColor: 'white' }} />
                                    </DateRangePicker>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row>

                        <Col style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} span={24}>
                            <Link to={"/ec2/start-stop-ec2"}> <Button type="primary" shape="round" size={'large'}>
                                Start/Stop EC2 instance
        </Button>
                            </Link>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table pagination={{ pageSize: 3 }} dataSource={data} columns={columns} />;

            </Col>

                    </Row>
                    <div style={{ backgroundColor: '#f8f6f6', width: '300vh', position: 'relative', right: '70px' }}>&nbsp;</div>
                    <Row>
                        <Col span={24}>
                            <br />
                            <div style={{ fontWeight: "bold", fontSize: '14px' }}>List of recent actions - Start/Stop EC2 Instance</div>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={24}>
                            <RecentActionsTable />
                        </Col>
                    </Row>
                </div>
            </Route>
            <Route path={'/ec2/start-stop-ec2'}>
                <Link to={"/ec2/start-stop-ec2"}><Button style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }} type="primary" shape="round" size={'large'}>
                  {/*  <BackwardFilled />     Go Back */}
        </Button>
                </Link>
                <br />
                <h6>EC2 Instance Start/Stop</h6>
                <p>Perform an Ec2 manual start or Stop action</p>
                <div style={{ display: "grid", gridTemplateColumns: '1fr' }}>
                    <Form id="ec2Form" style={{ justifySelf: "left" }}{...layout} form={form} name="control-hooks" onFinish={onFinish}>
                        <Form.Item
                            name="useremail"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}

                        >
                            <Input style={{ background: '#dedede' }} readOnly={true} />
                        </Form.Item>
                        <Form.Item
                            name="connector"
                            label="Connector Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="instance_id"
                            label="Instance ID"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="region"
                            label="Region"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="action"
                            label="Action"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a option and change input text above"
                                allowClear
                            >
                                <Option value="start">start</Option>
                                <Option value="stop">stop</Option>

                            </Select>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                {spinner ? <Spin /> : 'Submit'}
                            </Button>
                            <Button htmlType="button" onClick={onReset}>
                                Reset
        </Button>

                        </Form.Item>
                        {error && <p style={{ color: 'red', fontSize: "14px" }}>Error :{error} Action Not Successful</p>}
                        {success && <p style={{ color: 'green', fontSize: "14px" }}>{success}</p>}
                    </Form>

                </div>
            </Route>

        </>
    )
}

export default Ec2
