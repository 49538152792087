import axios from "axios";
import generator from "generate-password";

class UserAPIHelper {
  static async create(group, email) {
    var password = generator.generate({
      length: 6,
      numbers: true,
      symbols: true,
      uppercase: true,
      lowercase: true,
    });
    let params = {
      email: email,
      password: password + "P)4f",
      groupName: group,
      accountEmail: localStorage.getItem('accountEmail')
    };
    return await axios
      .post("https://ndfcrub6si.execute-api.eu-west-1.amazonaws.com/Prod/User", params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  static async getUsers(group){
    return await axios
      .get("https://ndfcrub6si.execute-api.eu-west-1.amazonaws.com/Prod/User?group=" + group)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  static async deleteUser(email){
    let params = {
      email: email
    };
    return await axios
      .post("https://ndfcrub6si.execute-api.eu-west-1.amazonaws.com/Prod/DeleteUser", params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }
}


export default UserAPIHelper;
