import axios from 'axios';

class StatisticAPIHelper{
    static async get(fromDate, toDate)
    {
        let url = 'https://6u4abwy2n4.execute-api.eu-west-1.amazonaws.com/Prod/';
        
        return await axios.get(url + 'getcostusagemonthly?authuseremail=' + localStorage.getItem('accountEmail') + '&startdate=' + fromDate + '&enddate=' + toDate )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async dailyDataGet(fromDate, toDate)
    {
        let url = 'https://2lmgnbocl3.execute-api.eu-west-1.amazonaws.com/Prod/';
        
        return await axios.get(url + 'getcostandusagedaily?authuseremail=' + localStorage.getItem('accountEmail') + '&startdate=' + fromDate + '&enddate=' + toDate )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async getForcastData()
    {
              
        return await axios.get('https://szlbhyd9fa.execute-api.eu-west-1.amazonaws.com/Prod/forecastcost?authuseremail=' + localStorage.getItem('accountEmail') )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }
}
export default StatisticAPIHelper;