import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
import cloudplexo from "../../assets/images/cloudplexlogo.png";
import "./menu-left.css"
const { Sider } = Layout;
const { SubMenu } = Menu;

export function MenuLeft(props) {

  return <Sider width={250} className="site-layout-background">
    <Menu
      mode="inline"
      id="left-menu-component"
      defaultSelectedKeys={['1']}
      style={{ height: '100%', borderRight: "19px" , backgroundColor: "#1f78b4" }}
      className="menu"
      >
      <div className="ml-3">
        <img src={cloudplexo} width="170" height="140" alt="cloud plexo logo" />
      </div>
      <Menu.Item key="1" className="submenu-item-custom"><Link to="/dashboard"><span className="submenu-item-name">Home</span></Link></Menu.Item>
      <SubMenu key="sub7" title="Daily Cost" className="submenu-item-name">
        <SubMenu key="sub8" title="AWS" className="submenu-item-name">
          <Menu.Item key="7"><Link to="/daily-dashboard"><span className="submenu-item-name">Daily</span></Link></Menu.Item>
        </SubMenu>
      </SubMenu>
      <SubMenu key="sub23" title="AWS Cloud Advisor" className="submenu-item-name">
          <Menu.Item key="22"><Link to="/summary-advisor"><span className="submenu-item-name">Summary Advisor</span></Link></Menu.Item>
      </SubMenu>
      <Menu.Item key="3" className="submenu-item-custom"><Link to="/notifications"><span className="submenu-item-name">Notifications</span></Link></Menu.Item>
      <SubMenu key="inventory" title="Inventory" className="submenu-item-name">
        <SubMenu key="aws" title="AWS" className="submenu-item-name">
          <Menu.Item key="9"><Link to="/inventory"><span className="submenu-item-name">Inventory</span></Link></Menu.Item>
          <Menu.Item key="10"><Link to="/ec2/start-stop-ec2"><span className="submenu-item-name">Start/stop EC2</span></Link></Menu.Item>
          <Menu.Item key="11"><Link to="/rds/start-stop-rds"><span className="submenu-item-name">Start/stop RDS</span></Link></Menu.Item>
        </SubMenu>
      </SubMenu>
      <SubMenu key="sub4" title="Scheduler" className="submenu-item-name">
        <SubMenu key="sub5"  title="AWS" className="submenu-item-name">
          <Menu.Item key="4"><Link to="/aws-schedular"><span className="submenu-item-name">EC2 Scheduler</span></Link></Menu.Item>
          <Menu.Item key="5"><Link to="/rds-schedular"><span className="submenu-item-name">RDS Scheduler</span></Link></Menu.Item>
        </SubMenu>
      </SubMenu>
      <SubMenu key="sub5" title="Recommendations" className="submenu-item-name">
        <SubMenu key="sub6" title="AWS" className="submenu-item-name">
          <Menu.Item key="6"><Link to="/rightsizing-recommendation"><span className="submenu-item-name">Rightsizing</span></Link></Menu.Item>
          <SubMenu key="sub633" title="Recommendations" className="submenu-item-name pl-30">
            <Menu.Item key="34433"><Link to="/idlerds"><span className="submenu-item-name">Idle RDS</span></Link></Menu.Item>
            <Menu.Item key="34422"><Link to="/idleec2"><span className="submenu-item-name">Idle EC2</span></Link></Menu.Item>
            <Menu.Item key="34214"><Link to="/idleeip"><span className="submenu-item-name">Idle EIP</span></Link></Menu.Item>
            <Menu.Item key="321244"><Link to="/idleebs"><span className="submenu-item-name">Idle EBS</span></Link></Menu.Item>
            <Menu.Item key="3212544"><Link to="/idleelb"><span className="submenu-item-name">Idle ELB</span></Link></Menu.Item>
            <Menu.Item key="321254422"><Link to="/summary"><span className="submenu-item-name">Summary</span></Link></Menu.Item>
          </SubMenu>
        </SubMenu>
      </SubMenu>
      <SubMenu key="sub2" title="Administration" className="submenu-item-name">
        <SubMenu key="sub9" title="Cloud Connectors" className="submenu-item-name">
        <Menu.Item key="6"><Link to="/aws-connector"><span className="submenu-item-name">AWS</span></Link></Menu.Item>
        <Menu.Item key="7"><Link to="/azure-conector"><span className="submenu-item-name">Azure</span></Link></Menu.Item>
        <Menu.Item key="8"><Link to="/google-conector"><span className="submenu-item-name">Google Cloud</span></Link></Menu.Item>
        </SubMenu>
        <Menu.Item key="9"><Link to="/users"><span className="submenu-item-name">Users</span></Link></Menu.Item>
       
      </SubMenu>



    </Menu>
  </Sider>

}

export default MenuLeft;