import React, {useEffect} from 'react';
import {Hub, Logger} from '@aws-amplify/core';
import {AuthService} from "../../services/auth-service";
import {Link, useHistory} from "react-router-dom";
import logo from '../../images/logo.png';
import {
    Container,
    Row,
    Col,
    Label,
    FormGroup,
    Button,
    Card,
    CardBody
  } from "reactstrap";
import {notification} from 'antd';
import FeatherIcon from "feather-icons-react";
import user02 from "../../assets/images/user/tech-cloud-bg-2.jpg";
import { AvForm, AvField } from "availity-reactstrap-validation";

export function RegisterConfirmForm() {

    const logger = new Logger("RegisterConfirmForm");
    const history = useHistory();


   // const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');

        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };
    });

    // Default handler for listening events
    const onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        if (channel === AuthService.CHANNEL &&
            (AuthService.AUTH_EVENTS.REGISTER_CONFIRM)) {
            if (!payload.success) {
                //setErrorMessage(payload.message);
                notification.open({
                    type: 'error',
                    message: 'Could not log in',
                    description: payload.message,
                    duration: 10
                });
            } else {
                notification.open({
                    type: 'success',
                    message:
                        'Perfect!',
                    description: 'You have confirmed your email. Now you can login',
                    duration: 15
                });

                history.push("/")
            }
        }
    };

    const onFinish = (event, values) => {
        // console.log('Success:', values);
        AuthService.confirmSignUp(values.email, values.code);
    };



    return <React.Fragment>
    
    <section className="cover-user bg-white" style={{width:'100%'}}>
      <Container fluid className="px-0">
        <Row className="no-gutters position-relative">
          <Col lg={4} xs={{ order: 2 }} className="cover-my-30 ">
            <div className="cover-user-img d-flex align-items-center">
              <Row>
                <Col xs={12}>
                  <Card
                    className="login-page border-0"
                    style={{ zIndex: 1 }}
                  >
                    <CardBody className="p-0">
                    <Row style={{justifyContent: 'center'}}>
                            <img src={logo} style={{width : '150px', height : '150px'}} alt=""></img>
                        </Row>
                      <h4 className="card-title text-center">Confirm Registration</h4>
                      <AvForm  onValidSubmit={onFinish} className="login-form mt-4">
                        <Row>
                          
                          <Col md="12">
                            <FormGroup className="position-relative">
                              <Label for="email">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="text"
                                className="form-control pl-5"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter your email",
                                  },
                                  pattern: {
                                    value:
                                      "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                    errorMessage: "E-Mail is not valid!",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="position-relative">
                              <Label for="code">
                                Verification Code{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="lock"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="text"
                                className="form-control pl-5"
                                name="code"
                                id="code"
                                placeholder="Enter Verification Code"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter your verification code",
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12" className="mb-0">
                            <Button color="primary" block style={{height:'50px'}}>
                              Confirm
                            </Button>
                          </Col>
                       
                          {/* <Col lg="12" className="mt-4 text-center">
                            <h6>Or Signup With</h6>
                            <ul className="list-unstyled social-icon mb-0 mt-3">
                              <li className="list-inline-item">
                                <Link to="#" className="rounded mr-1">
                                  <i>
                                    <FeatherIcon
                                      icon="facebook"
                                      className="fea icon-sm fea-social"
                                    />
                                  </i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link to="#" className="rounded mr-1">
                                  <i>
                                    <FeatherIcon
                                      icon="github"
                                      className="fea icon-sm fea-social"
                                    />
                                  </i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link to="#" className="rounded mr-1">
                                  <i>
                                    <FeatherIcon
                                      icon="twitter"
                                      className="fea icon-sm fea-social"
                                    />
                                  </i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link to="#" className="rounded">
                                  <i>
                                    <FeatherIcon
                                      icon="gitlab"
                                      className="fea icon-sm fea-social"
                                    />
                                  </i>
                                </Link>
                              </li>
                            </ul>
                          </Col> */}
                          <Col className="text-center">
                            <p className="mb-0 mt-3">
                              <small className="text-dark mr-2">
                                Already have an account ?
                              </small>{" "}
                              <Link
                                to="/login"
                                className="text-dark font-weight-bold"
                              >
                                Sign in
                              </Link>
                            </p>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            lg={8}
            className="offset-lg-4 padding-less img order-1"
            style={{ backgroundImage: `url(${user02})` }}
          ></Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>

    // <div style={{marginTop : 200, minHeight:'485px'}}>
    //     <Row style={{display: 'flex', justifyContent: 'center', margin: "15px"}}>
    //         <img src={logo} style={{width : '150px', height : '50px'}} alt=""></img>           
    //     </Row>
    //     <Row style={{display: 'flex', justifyContent: 'center', margin: "15px"}}>
    //         Use the emailed code to confirm your email
    //     </Row>
    //     <Row>
    //         <Form
    //             name="basic"
    //             onFinish={onFinish}
    //             onFinishFailed={onFinishFailed}
    //             style={styles.loginForm}>
    //             <Form.Item
    //                 name="username"
    //                 rules={[
    //                     {
    //                         required: true,
    //                         message: 'Please input your email',
    //                     }
    //                 ]}>
    //                 <Input
    //                     prefix={<UserOutlined/>}
    //                     placeholder="Email"
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 name="code"
    //                 rules={[
    //                     {
    //                         required: true,
    //                         message: 'Please input your confirmation code!'
    //                     }
    //                 ]}>

    //                 <Input
    //                     prefix={<LockOutlined/>}
    //                     type="string"
    //                     placeholder="Code"
    //                 />
    //             </Form.Item>
    //             <Form.Item>

    //                 <Button type="primary" htmlType="submit" style={styles.loginFormButton}>
    //                     Confirm Email
    //                 </Button>
    //                 Already confirmed? <Link to="login">Login</Link>
    //             </Form.Item>
    //         </Form>
    //     </Row>
      
    // </div>;

}
