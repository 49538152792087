import axios from 'axios';

class AWSSchedularAPIHelper{
    static async get()
    {
        // let url = config.aws_api_gateway;
        
        return await axios.get(`https://f60lq76kz3.execute-api.eu-west-1.amazonaws.com/Prod/ec2scheulerstate?useremail=${localStorage.getItem('email')}` )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async create(data)
    {
         let url = 'https://44l6smqry6.execute-api.eu-west-1.amazonaws.com/Prod/ec2schedule';
         return await axios.post(url, data)
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }
    static async delete(data)
    {
         let url = 'https://ybxa6kml8d.execute-api.eu-west-1.amazonaws.com/Prod/ec2deletescheduler';
         return await axios.post(url, data )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }
}

export default AWSSchedularAPIHelper;