import React, { useState } from "react";
import "./assets/css/materialdesignicons.min.css";
import "./assets/css/colors/default.css";
import "./App.scss";
import { Layout } from "antd";
import MyHeader from "./components/layout/header";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { HomeScreen } from "./screens/secure-home/home-screen";
import { CostAnalysis } from "./screens/cost-analysis/cost-analysis";
import { Notifications } from "./screens/notifications/notifications";
import { Recommendations } from "./screens/recommendations/recommendations";
import GoogeCloud from "./screens/cloud-conectors/googeCloud";
import Azure from "./screens/cloud-conectors/azure";
import AWSConnector from "./screens/cloud-conectors/aws-connector/aws-connector";
import AddConnector from "./screens/cloud-conectors/aws-connector/add-connector";
import AddConnectorRoleArn from "./screens/cloud-conectors/aws-connector/add-connector-arn";
import RightsizingRecommendation from "./screens/recommendations/aws/rightsizing-recommendation";
import Inventory from "./screens/Inventory/Inventory";
import Dashboard from "./screens/dashboard/dashboard";
import UserManagement from "./screens/user/userManagement"
import DailyDashboard from "./screens/daily-dashboard/dashboard";
import { AuthScreen } from "./screens/auth/auth-screen";
import Amplify from "@aws-amplify/core";
import awsConfig from "./configs/aws-configs";
import MenuLeft from "./components/layout/menu-left";
import HashLoader from "react-spinners/HashLoader";
import LoadingOverlay from "react-loading-overlay";
import { AWSSchedular } from "./screens/Schedulars/AWS Schedular/aws-schedular";
import AddAWSSchedular from "./screens/Schedulars/AWS Schedular/addAws-schedular";
import { RDSSchedular } from "./screens/Schedulars/RDS Schedular/rds-schedular";
import AddRDSSchedular from "./screens/Schedulars/RDS Schedular/addRDSSchedular";
import IDLEEC2 from "./screens/recommendations/ServiceRecommendation/IDLEEC2";
import Ec2 from "./screens/ec2/ec2";
import Rds from "./screens/rds/rds";
import SummaryAdvisor from "./screens/Cloud-Advisor/Summary-Advisor/SummaryAdvisor";
import IDLEEIP from "./screens/recommendations/ServiceRecommendation/IDLEEIP";
import IDLEEBS from "./screens/recommendations/ServiceRecommendation/IDLEEBS";
import IDLERDS from "./screens/recommendations/ServiceRecommendation/IDLERDS";
import IDLEELB from "./screens/recommendations/ServiceRecommendation/IDLEELB";
import Summary from "./screens/recommendations/ServiceRecommendation/Summary";

const { Content, Footer } = Layout;

Amplify.configure(awsConfig);

function App() {
 const [isAuthenticated, setIsAuthenticated] = useState(false);
 const [loading, setLoading] = useState(false);
 const [user, setUser] = useState('');

 const userHasAuthenticated = (authenticated) => {
  setIsAuthenticated(authenticated);
 };

 const setUserDetails = (user) => {
  setUser(user);
 };

 const getUser =() => {
   return user;
 }

 const setLoadingWheel = (isloading) => {
  setLoading(isloading);
 };

 return (
  <React.Fragment>
   <Router>
    <LoadingOverlay
     active={loading}
     spinner={<HashLoader color={"#0080FF"} size={100} />}
    >
     <Layout>
      <Layout
       style={
        isAuthenticated
         ? { height: "100%", minHeight: "1060px" }
         : { height: "1060px" }
       }
      >
       {isAuthenticated ? <MenuLeft /> : null}
       <Layout>
        {isAuthenticated ? (
         <MyHeader userHasAuthenticated={userHasAuthenticated} />
        ) : null}
        <Content
         style={
          isAuthenticated
           ? { padding: "0 50px", width: "100%", background: "#F8F6F6" }
           : { padding: "0 0px", marginTop: 0, width: "100%" }
         }
        >
         <div
          style={
           isAuthenticated
            ? {
               background: "#f8f7fc",
               padding: 24,
               width: "100%",
               marginTop: "20px",
              }
            : {
               background: "#f8f7fc",
               padding: 0,
               width: "100%",
               marginTop: "0px",
              }
          }
         >
          <Route
           exact
           path={"/home"}
           render={(props) => (
            <HomeScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/cost-analysis"}
           render={(props) => (
            <CostAnalysis
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/dashboard"}
           render={(props) => (
            <Dashboard
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/notifications"}
           render={(props) => (
            <Notifications
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/summary-advisor"}
           render={(props) => (
            <SummaryAdvisor
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/aws-schedular"}
           render={(props) => (
            <AWSSchedular
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/addAWS-schedular"}
           render={(props) => (
            <AddAWSSchedular
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/rds-schedular"}
           render={(props) => (
            <RDSSchedular
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/addRDS-schedular"}
           render={(props) => (
            <AddRDSSchedular
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/recommendations"}
           render={(props) => (
            <Recommendations
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/idleec2"}
           render={(props) => (
            <IDLEEC2
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/idlerds"}
           render={(props) => (
            <IDLERDS
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/idleelb"}
           render={(props) => (
            <IDLEELB
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/idleeip"}
           render={(props) => (
            <IDLEEIP
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/idleebs"}
           render={(props) => (
            <IDLEEBS
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/summary"}
           render={(props) => (
            <Summary
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           path={"/ec2"}
           render={(props) => (
            <Ec2 {...props} userHasAuthenticated={userHasAuthenticated} />
           )}
          />
          <Route
           exact
           path={"/inventory"}
           render={(props) => (
            <Inventory
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           path={"/rds"}
           render={(props) => (
            <Rds {...props} userHasAuthenticated={userHasAuthenticated} />
           )}
          />
          <Route
           exact
           path={"/rightsizing-recommendation"}
           render={(props) => (
            <RightsizingRecommendation
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/google-conector"}
           render={(props) => (
            <GoogeCloud
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/azure-conector"}
           render={(props) => (
            <Azure {...props} userHasAuthenticated={userHasAuthenticated} />
           )}
          />
          <Route
           exact
           path={"/aws-connector"}
           render={(props) => (
            <AWSConnector
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/add-connector"}
           render={(props) => (
            <AddConnector
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/add-connector-role-arn"}
           render={(props) => (
            <AddConnectorRoleArn
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/register"}
           render={(props) => (
            <AuthScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/registerconfirm"}
           render={(props) => (
            <AuthScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/forgotpassword1"}
           render={(props) => (
            <AuthScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/forgotpassword2"}
           render={(props) => (
            <AuthScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
            />
           )}
          />
          <Route
           exact
           path={"/forcechangepassword"}
           render={(props) => (
            <AuthScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             getUser={getUser}
            />
           )}
          />
          <Route
           exact
           path="/login"
           render={(props) => (
            <AuthScreen
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setUserDetails ={setUserDetails}
            />
           )}
          />
          <Route
           exact
           path={"/daily-dashboard"}
           render={(props) => (
            <DailyDashboard
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route
           exact
           path={"/users"}
           render={(props) => (
            <UserManagement
             {...props}
             userHasAuthenticated={userHasAuthenticated}
             setLoadingWheel={setLoadingWheel}
            />
           )}
          />
          <Route exact path="/">
           <Redirect to="/login" />
          </Route>
         </div>
        </Content>
        <Footer style={{ textAlign: "center" }}> © 2021 CloudPlexo </Footer>
       </Layout>
      </Layout>
     </Layout>
    </LoadingOverlay>
   </Router>
  </React.Fragment>
 );
}

export default App;
