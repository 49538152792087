import React, { Component } from "react";
import * as AWS from 'aws-sdk'
import {Auth} from "aws-amplify";
import { Col, Row} from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Tabs, Button } from 'antd';
import awsQueries from "./awsQueries"
import CustomCollapse from "./Collapse";
import AWSConectorAPIHelper from '../../API/awsConectorAPIHelper';
import InventoryHepler from '../../API/InventoryHepler';
import ReactList from 'react-list';

const { TabPane } = Tabs;

export default class Inventory extends Component {
     constructor(props) {
      super(props);
      
       this.state = {
            statList : [],
            accountList: [],
            connectorList:[],
            AWS_REGIONS : [],
            regions: [],
            regionsData: [],
            forcastList:[],
            costTypeList: ['Cost Type'],
            perviousMonthStartDate: new Date(),
            currentMonthStartDate : new Date(),
            perviousMonthEndDate: new Date(),
            currentMonthEndDate : new Date(),
            accountId : '-1',
            connectorId : '-1',
            costType : '',
            serviceList : [],
            calculatedServiceList : [],
            lastMonthTotalCost: 0.00,
            previousMonthTotalCost : 0.00,
            currentMonthTotalCost:0.00,
            perviousMonthLabel :'',
            currentMonthLabel :'',
            startDate : new Date(),
            endDate : new Date(),
            totalRequestsSend : 0,
            totalRequestsComplete : 0,
            mode: 'top'

       } 
    }

     credentialsVerify = () =>  {
       const {access_key_input , secret_key_input , aws_session_token} = this.state?.credentials
        AWS.config.region = 'us-east-1'
        const credentials = {
            accessKeyId: access_key_input || "",
            // accessKeyId:  "AKIAVJARN6YS5W2NHYU3",
            // secretAccessKey: "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",
            secretAccessKey: secret_key_input || "",
        }
        if (aws_session_token) {
            credentials.sessionToken = aws_session_token
        }
      AWS.config.credentials = new AWS.Credentials(credentials)
      var sts = new AWS.STS()
        sts.getCallerIdentity((err, data) => {
          if (err) {
            // $('#aws-credentials-verify').prop('disabled', false);
            console.log(err);
            return false
          }
            this.contentRegionsCreate();
        })
        return false
      }
       contentRegionsCreate = () => {
          this.props.setLoadingWheel(true);
          var ec2 = new AWS.EC2()
          ec2.describeRegions((err, data)=> {
          let regions = data.Regions.map(function(r){ return r.RegionName }).sort();
          regions = ["global"].concat(regions);
          this.setState({AWS_REGIONS : regions , regions})
          regions.forEach(nameRegion => {
            this.setState({[nameRegion] : {data : [] , skip: 0, limit: 20}})
          })
          this.props.setLoadingWheel(false);
          this.inventoryServices()
        })
      }

    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
               console.log("User is logged-in");
               this.props.userHasAuthenticated(true);
               this.getConntectors();
                // this.credentialsVerify()
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );

    }

    getConntectors = async () => {
      this.props.setLoadingWheel(true);
      const connectors = await AWSConectorAPIHelper.getRoleArnList();
      this.setState({connectorList : connectors.data.body.map(x => x[1].stringValue)})
      this.props.setLoadingWheel(false);
    }

    getAccessToken = async () => {
      if(!this.state.connectorId){
        return;
      }
      this.props.setLoadingWheel(true);
      const credentials = await InventoryHepler.getCrednetials(this.state.connectorId);
      if(credentials.status === 200 && !credentials.data.hasOwnProperty('errorMessage')){
        // console.log(credentials , "credentials")
        this.setState({credentials : credentials.data.body} , () => { this.credentialsVerify()})
        this.props.setLoadingWheel(false);
      }else {
        alert(credentials.data.errorMessage)
        this.props.setLoadingWheel(false);
      }
    }



  onConnectorChange = (event) =>{
    this.setState({connectorId : event.target.value })
  }
  inventoryServices =() => {
    this.props.setLoadingWheel(true);
    const {AWS_REGIONS} = this.state
    AWS_REGIONS.forEach(region => {
      let dataarr = [];
      for (let q=0; q<awsQueries.length; q++) {
        const query = awsQueries[q]
        if (region === 'global' && (!query.hasOwnProperty('region') || query.region !== 'global')) {
          continue // show only global resources on global tab
        }
        if (region !== 'global' && query.hasOwnProperty('region') && query.region === 'global') {
          continue // do not show global resources in other regions
        }
        if (query.hasOwnProperty('region') && query.region !== region) {
          continue // restrict to specific region only
        }
        if (query.hasOwnProperty('skip_regions') && query.skip_regions.includes(region)) {
          continue // skip specified regions
        }
        dataarr.push({query ,queryID : query.id , region})
      }

      // console.log("dataarr" , /*dataarr*/)
      this.setState({
          [region] : {data : dataarr , }
      })
      this.props.setLoadingWheel(false);
    })
  }
  renderItem = (index, key , name) => {
    return <CustomCollapse key={key} data={this.state[name].data[index]} />;
  }
    render(){
        return (
           <div>
               <div>
               <Row>
                   <Col span={4}>
                        <Row>
                            <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>                              
                                <select className='select-dash' defaultValue='AWS' disabled style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    
                                    <option value="AWS">AWS</option>
                                </select>
                              
                            </div>
                        </Row>
                    </Col>
                     <Col span={8}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.connectorId} onChange={this.onConnectorChange} style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    <option key={"select Connector Name"} value={"select Connector Name"}>Select Connector Name</option>
                                    
                                    {this.state.connectorList.map(account => {
                                         return (
                                         <option key={account} value={account}>{account}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={this.getAccessToken} shape="round" size={'large'}>
                            AWS Inventory Scan
                      </Button>
                    </Col>
               </Row>
               </div>
               <br/>
               <br/>
               <div>
        <Tabs defaultActiveKey="1" tabPosition={"left"}>
            {this.state.regions.map(region => (
              <TabPane tab={region} key={region}>
                  <div style={{overflow: 'auto', maxHeight: '950px'}}>
                    <ReactList
                      threshold={200} 
                      itemRenderer={(index , key) => this.renderItem(index , key, region)}
                      length={this.state[region]?.data.length}
                      type='simple'
                      // getVisibleRange={(a , b) => console.log(a, b)}
                    />
                  </div>
              </TabPane>
            ))}
        </Tabs>
      </div>
           </div>

        )
    }

}