import React, { Component } from "react";
import {Auth} from "aws-amplify";
import {Col, Row, Card} from 'antd';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import recommandationAPIHelper from '../../../API/recommandationAPIHelper';
// import DateRangePicker from 'react-bootstrap-daterangepicker';

export default class RightsizingRecommendation extends Component {
     constructor(props) {
      super(props);
      
       this.state = {           
           loading: false,
           recommList:[],
           ooppertunities : 0,
           estimatedMonthlySavings : 0.00,
           estimatedMonthlyCost : 0.00,
           estimatedSavings: 0.00,
           accountList:[],
           accountName : '',
          //  selectedDate :'',
           selectedRecommList:[]
       }

       this.options = {
        defaultSortName: 'instanceId',  // default sort column name 
        defaultSortOrder: 'acs',  // default sort order
        noDataText: 'No data available in the table',
        page: 1,  // which page you want to show as default
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '20', value: 20
        }, {
          text: 'All', value: this.state.selectedRecommList.length
        }], // you can change the dropdown list for size per page
        sizePerPage: 10,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 10,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        hideSizePerPage: true,  //You can hide the dropdown for sizePerPage
      };
    }

    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
             //   logger.log("User is logged-in");
                this.props.userHasAuthenticated(true);
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );
        //this.setState({selectedDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString().substring(0,10)}, () =>      this.getData())
        this.getData();

    }

    getData = () => {
      this.props.setLoadingWheel(true);
      let processedAccountList = [];
      this.setState({recommList:[], ooppertunities: 0, estimatedMonthlySavings:0.00, estimatedSavings:0.00})
        recommandationAPIHelper.get()
        .then(res => {
            if(res.status === 200){
                let recomList = [];    
                if(res.data.body.length > 0){
                    res.data.body.forEach(element => {
                    let item = {
                        instanceId : element[0].stringValue,
                        instance_name: element[1].stringValue,
                        instance_type_current : element[2].stringValue,
                        instance_type_recommended : element[3].stringValue,
                        cpu	 : parseFloat(element[4].stringValue).toFixed(2),
                        monthly_cost : parseFloat(element[5].stringValue).toFixed(2),
                        estimated_savings : parseFloat(element[6].stringValue).toFixed(2),
                        date : element[7].stringValue,
                        month : new Date(element[7].stringValue).getMonth() + 1
                    }

                    let account =  processedAccountList.filter(acc => acc.name === element[1].stringValue);
                    if(account.length === 0)
                    processedAccountList.push({id : element[1].stringValue, name : element[1].stringValue});
                    
                    recomList.push(item);
                    });

                    this.setState({recommList : recomList, accountList : processedAccountList, accountName : processedAccountList[0] ? processedAccountList[0].name : ''}, this.processDataSet(recomList, processedAccountList[0].name));
                    
                      this.props.setLoadingWheel(false);
                }
                else{
                  this.setState({recommList : []});
                  this.props.setLoadingWheel(false);
                }
                    }
                    else{
                      this.setState({recommList : []});
                      this.props.setLoadingWheel(false);
                    }
        }).catch(() => {
          this.props.setLoadingWheel(false);
        })
    }

    processDataSet = (rl, ac) => {
        let ooCount =0, emc=0.0, es = 0.0;
        
        let selectedRecommList = rl.filter(item => {return item.instance_name === ac});
        selectedRecommList.forEach(element => {
            if(element.month === (new Date().getMonth() + 1))
                ooCount++;
            emc = emc + parseFloat(element.monthly_cost);
            es = es + parseFloat(element.estimated_savings);
        });
        let estimatesSaving = 0.00;
        if(es > 0)
          estimatesSaving = ((emc - es )/es) * 100;
        this.setState({ooppertunities : ooCount, estimatedMonthlySavings  :es.toFixed(2), estimatedSavings : estimatesSaving.toFixed(2), estimatedMonthlyCost : emc.toFixed(2), selectedRecommList : selectedRecommList});
    }

    OnClickActions(cell, row) {
    return (
      <div>
        <Row>
        <Col span={6}>
        <button className='btn-link'>Start</button>
        </Col>
        <Col span={6}>
        <button className='btn-link'>Stop</button>
        </Col>
        <Col span={6}>
        <button className='btn-link'>Terminate</button>
        </Col>
        </Row>
      </div>
    );
  }

  onLoadMonthlySavingsColumn(cell, row) {
    return (
        <span>${row.monthly_cost}</span>
    )
  }

  onLoadEstimatesSavingsColumn(cell, row) {
    return (
        <span>${row.estimated_savings}</span>
    )
  }

  // handleCallback = (start, end, label) => {
  //   let startDate = start.toDate();
  //   startDate.setDate(startDate.getDate() + 1);
  //   //console.log(startDate.toISOString().substring(0, 10))
  //   this.setState({selectedDate : startDate.toISOString().substring(0,10)}, () =>      this.processDataSet())
  // };

  onAccountChange = (event) =>{
    this.setState({accountName : event.target.value }, ()=> {this.processDataSet()});
  }

     render(){
        return (
          <div>
              <div>
                <span style={{fontWeight:'bold'}}>Recommendations</span>
                <br/>
                <br/>
                <div>
                  <Row>
                    <Col span={6}>
                          <Row>
                          <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.accountId} onChange={this.onAccountChange} style={{width:'100%', backgroundColor: '#ffffff',border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.accountList.map((account, i) => {
                                         return (
                                         <option key={`${account}---${i}`} value={account.id}>{account.name}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                          </Row>
                    </Col>
                    {/* <Col span={6}>
                        <Row>
                             <div style={{width:'75%', padding:'5px', backgroundColor: '#3386ff'}}>
                                <DateRangePicker onCallback={this.handleCallback} 
                                    initialSettings={{ startDate: new Date() , singleDatePicker: true,}}
                                    >
                                    <input type="text" className="form-control" style={{width:'100%', backgroundColor: '#3386ff', color: 'white', padding : '5px', borderColor:'white'}} />
                                    </DateRangePicker>
                            </div>
                        </Row>
                    </Col> */}
                  </Row>
                </div>
                <br/>
                <br/>
                <div>
                <Row>
                    <Col span={6}>
                    <Card style={{ width: '100%' , borderRadius: 8}}>
                        <Row>
                            Optimization opportunities
                        </Row>
                        <Row>
                            <span style={{fontWeight:'bold', fontSize:'xxx-large'}}>{this.state.selectedRecommList.length}</span>
                        </Row>
                        </Card>
                    </Col>
                    <Col span={2}>
                        {/* <Divider type="vertical" style={{ height: "100%" }}/> */}
                    </Col>
                    <Col span={7}>
                    <Card style={{ width: '100%' , borderRadius: 8}}>
                         <Row>
                            Total Estimated monthly savings
                        </Row>
                        <Row>
                            <span style={{fontWeight:'bold', fontSize:'xxx-large'}}>${this.state.estimatedMonthlySavings}</span>
                        </Row>
                        </Card>
                    </Col>
                     <Col span={2}>
                        {/* <Divider type="vertical" style={{ height: "100%" }}/> */}
                    </Col>
                   {/* <Col span={6}>
                    <Card style={{ width: '100%' , borderRadius: 8}}>
                         <Row>
                            Total Estimated savings (%)
                        </Row>
                        <Row>
                            <span style={{fontWeight:'bold', fontSize:'xxx-large'}}>{this.state.estimatedSavings ? this.state.estimatedSavings : 0.00}%</span>
                        </Row>
                        </Card>
                    </Col>
                  */}
                </Row>
                </div>
              </div>
              <br/>
              <br/>
              <div>
              <Card style={{ width: '100%' , borderRadius: 8}}>
                <BootstrapTable data={this.state.selectedRecommList} options={this.options} 
                  pagination={true} search={false} width="150" striped hover condensed
                  scrollTop={'Bottom'}>
                  <TableHeaderColumn dataField='instanceId' width="100">INSTANCE ID</TableHeaderColumn>
                 
                  <TableHeaderColumn headerAlign='center'
                    isKey dataField='instance_type_current' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center'>INSTANCE TYPE CURRENT</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='instance_type_recommended'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"  thStyle={{ whiteSpace: 'normal' }}
                    dataSort>INSTANCE TYPE RECOMMENDED</TableHeaderColumn>   
                  <TableHeaderColumn headerAlign='center' dataField='cpu'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>CPU</TableHeaderColumn>   
                  <TableHeaderColumn headerAlign='center' dataFormat={this.onLoadMonthlySavingsColumn} dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>MONTHLY COST</TableHeaderColumn>   
                   <TableHeaderColumn headerAlign='center' dataFormat={this.onLoadEstimatesSavingsColumn} dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>ESTIMATES SAVINGS</TableHeaderColumn>  
                  <TableHeaderColumn headerAlign='center' dataField='date' dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>DATE</TableHeaderColumn>       
                  
                </BootstrapTable>
                </Card>
              </div>
            
          </div>
        )
     }

}