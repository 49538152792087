import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Hub, Logger} from "@aws-amplify/core";
import {AuthService} from "../../services/auth-service";
import logo from '../../images/logo.png';
import {
    Container,
    Row,
    Col,
    Label,
    FormGroup,
    Button,
    Card,
    CardBody
  } from "reactstrap";
import {notification} from 'antd';
import FeatherIcon from "feather-icons-react";
import user03 from "../../assets/images/user/cloud-tech-bg-1.jpg";
import { AvForm, AvField } from "availity-reactstrap-validation";

export function ForgotPasswordEmailForm() {

    const logger = new Logger("ForgotPasswordEmailForm");
    const history = useHistory();

    // const [errorMessage, setErrorMessage] = useState("");
    // const [userNotConfirmed, setUserNotConfirmed] = useState(false);

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');

        return function cleanup() {
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        }
    })

    const onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        if (channel === AuthService.CHANNEL && payload.event === AuthService.AUTH_EVENTS.PASSWORD_RESET) {
            logger.info(payload.message);
            if (!payload.success) {
                // setErrorMessage(payload.message)
                notification.open({
                    type: 'error',
                    message: "Couldn't reset your password",
                    description: payload.message,
                    duration: 15
                });
            } else {
                // setErrorMessage(null)
                notification.open({
                    type: 'info',
                    message: 'Check your mail. Use the code to change your password',
                    duration: 15
                });

                history.push("/forgotpassword2")

            }
        }
    };
    const onFinish = (event, values) => {
        logger.info('Received values of form: ', values);
        AuthService.forgotPassword(values.email);
    };

    return <React.Fragment>
    <section className="cover-user bg-white" style={{width:'100%'}}>
      <Container fluid className="px-0">
        <Row className="no-gutters position-relative">
          <Col lg={4} xs={{ order: 2 }} className="cover-my-30">
            <div className="cover-user-img d-flex align-items-center">
              <Row>
                <Col xs={12}>
                  <Card
                    className="login-page border-0"
                    style={{ zIndex: 1 }}
                  >
                    <CardBody className="p-0">
                    <Row style={{justifyContent: 'center'}}>
                            <img src={logo} style={{width : '150px', height : '150px'}} alt=""></img>
                        </Row>
                      <h4 className="card-title text-center">
                        Recover Account
                      </h4>
                      <AvForm onValidSubmit={onFinish} className="login-form mt-4">
                        <Row>
                          <Col lg="12">
                            <p className="text-muted">
                              Please enter your email address. You will
                              receive a link to create a new password via
                              email.
                            </p>
                            <FormGroup className="position-relative">
                              <Label for="email">
                                Email address{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="text"
                                className="form-control pl-5"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter your email",
                                  },
                                  pattern: {
                                    value:
                                      "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                    errorMessage: "E-Mail is not valid!",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <Button color="primary" block style={{height:'50px'}}>
                              Send
                            </Button>
                          </Col>
                          <div className="mx-auto">
                            <p className="mb-0 mt-3">
                              <small className="text-dark mr-2">
                                Remember your password ?
                              </small>{" "}
                              <Link
                                to="/login"
                                className="text-dark font-weight-bold"
                              >
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            lg={{ size: 8, offset: 4 }}
            xs={{ order: 1 }}
            className="padding-less img"
            style={{ backgroundImage: `url(${user03})` }}
          ></Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
    
    // <div style={{marginTop : 200, minHeight:'485px'}}>
    //     <Row style={{display: 'flex', justifyContent: 'center', margin: "15px"}}>
    //         <img src={logo} style={{width : '150px', height : '50px'}} alt=""></img>           
    //     </Row>
    //     <Row style={{display: 'flex', justifyContent: 'center', width: "200px"}}>Get a 'reset' code</Row>
    //     <Row>
    //         <Form name="basic"
    //               onFinish={onFinish}
    //               onFinishFailed={onFinishFailed}
    //               style={styles.loginForm}>
    //             <Form.Item
    //                 name="username"
    //                 rules={[
    //                     {
    //                         required: true,
    //                         message: 'Please input your email!',
    //                     }
    //                 ]}>
    //                 <Input
    //                     prefix={<UserOutlined/>}
    //                     placeholder="Email"
    //                 />
    //             </Form.Item>
    //             <Form.Item>
    //                 <Button type="primary" htmlType="submit" style={styles.loginFormButton}>
    //                     Send me a code
    //                 </Button>
    //                 Or try to <Link to="login">login</Link> </Form.Item>
    //         </Form>
    //     </Row>
    // </div>;

}
