import axios from 'axios';

class RecommandationAPIHelper{
    static async get()
    {
        return await axios.get('https://lsmi2t1xhc.execute-api.eu-west-1.amazonaws.com/Prod/recawsrightsizingec2?authuseremail=' + localStorage.getItem('email') )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }
}

export default RecommandationAPIHelper;