import React, {useEffect , useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Auth} from "aws-amplify";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import aWSSchedularAPIHelper from '../../../API/awsSchedularApiHandler';
import { Col, Row , notification ,Modal } from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Logger} from "@aws-amplify/core";


export function AWSSchedular (props) {
    const logger = new Logger("Notifications");
    const [schedulars, setSchedulars] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteObj, setDeleteObj] = useState({});
    const history = useHistory();
    const addAWSSchedular =() => {
        history.push('/addAWS-schedular');
    }
    const handleOk = () => {
      setIsModalVisible(false);
      deleteHandler(deleteObj);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
      setDeleteObj({});
    };
    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                history.push("/")
            else{
                logger.log("User is logged-in");
                props.userHasAuthenticated(true);
            }
        }).catch(err => {
                logger.log("Couldn't get the logged-in user for some reason: " + err);
                 props.userHasAuthenticated(false);
                history.push("/")
            }
        );
        getAWSSchedularList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const deleteHandler = (data) => {
        let obj = {...data}
        // delete obj["instance_id"]
        delete obj["id"]
        props.setLoadingWheel(true);
        aWSSchedularAPIHelper.delete(obj).then(res => {
        if(res.status === 200){
            getAWSSchedularList()
            setSchedulars([]) 
            notification.open({
                type: 'success',
                message: 'succesfully delete schedular',
                duration: 20
            });
            props.setLoadingWheel(false);
        }
        else{
            notification.open({
                type: 'error',
                message: 'Unable to delete the details.',
                duration: 20
            });
          props.setLoadingWheel(false);
        }
      }).catch(() => {
        notification.open({
            type: 'error',
            message: 'Could not delete data',
            description: 'Unable to delete the details.',
            duration: 20
            });
        props.setLoadingWheel(false);
      })
        // console.log("deleteHandler" , data)
    }
    const OnClickActions = (cell, row) => {
        return (
          <div>
            <Row style={{textAlign: 'center'}}>
                <Col  span={12}>
                    <DeleteOutlined onClick={() => {
                      setIsModalVisible(!isModalVisible);
                      setDeleteObj(row)
                    }} />
                </Col>
            </Row>
          </div>
        );
    }
    const getAWSSchedularList = () => {
        props.setLoadingWheel(true);
        aWSSchedularAPIHelper.get().then(res => {
        if(res.status === 200){ 
          let SchedularsList = [];
          // console.log("res.data " , res.data)    
          if(res.data.Items.length > 0){
            res.data.Items.forEach((element , index) => {
              let item = {
                id: index +1,
                action : element.action.S,
                connector : element.connector.S,
                cron_string : element.cron_string.S,
                instance_id : element.instance_id.S,
                region : element.region.S,
                rule_id : element.rule_id.S,
                useremail : element.useremail.S,
              }
              
              SchedularsList.push(item);
            });

            setSchedulars(SchedularsList)
          }
            props.setLoadingWheel(false);
        }
        else{
          props.setLoadingWheel(false);
        }
      }).catch(() => {
        props.setLoadingWheel(false);
      })
    }
    return (
        <div>
            <Row>
                <span onClick={addAWSSchedular} className='btn btn-primary' style={{ float: 'left'}}>Add EC2 Scheduler</span>    
            </Row>
            <br/>
            <Row>               
            
            <BootstrapTable data={schedulars} options={{}} 
                  pagination={false} search={false} width="150" striped hover condensed
                  scrollTop={'Bottom'}>
                  <TableHeaderColumn dataField='id' headerAlign='center' 
                  tdStyle={{ whiteSpace: 'normal' }} dataAlign='center' 
                  thStyle={{ whiteSpace: 'normal' }} dataSort width="50">#</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='rule_id' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center' >Rule ID</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='action'
                    isKey tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center'>action</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='connector'   dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>Connector</TableHeaderColumn>   
                  <TableHeaderColumn headerAlign='center' dataField='cron_string'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>cron-string</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='instance_id'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>Instance ID</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='region'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>Region</TableHeaderColumn>                
                  <TableHeaderColumn
                    headerAlign='center'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }}
                    width="50"
                    dataFormat={OnClickActions}
                    >Actions</TableHeaderColumn>
                </BootstrapTable>
                </Row>
                <Modal visible={isModalVisible} onOk={handleOk} okText="Delete" onCancel={handleCancel}>
                  <p>Are you sure you want to delete rule &nbsp; <b>{deleteObj.rule_id || ''}</b></p>
                </Modal>
                <br/>
                <br/>
            </div>
    )
}