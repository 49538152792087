import React from 'react';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';



export class RecentActionsTable extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        tableData: []
    };


    componentDidMount() {
        axios.get('https://y1hkxj8mo3.execute-api.eu-west-1.amazonaws.com/Prod/rdsstartstopstate', {
            params: {
                useremail: localStorage.getItem('email'),

            }
        }).then(res => {
            // console.log('rds:::', res)
            let resModified = res.data.Items.map((ele) => {
                ele.action = ele.action.S
                ele.connector = ele.connector.S
                ele.dbidentifier = ele.instance_id.S
                ele.region = ele.region.S
                ele.state_id = ele.state_id.S
                ele.useremail = ele.useremail.S
                return ele
            })
            this.setState({ tableData: resModified }, () => {
                // console.log(this.state.tableData)
            })
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
          </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
          </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
          </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'state_id',
                dataIndex: 'state_id',
                key: 'state_id',
                width: '30%',
                ...this.getColumnSearchProps('state_id'),
            },
            {
                title: 'action',
                dataIndex: 'action',
                key: 'action',
                width: '20%',
                ...this.getColumnSearchProps('action'),
            },
            {
                title: 'connector',
                dataIndex: 'connector',
                key: 'connector',
                ...this.getColumnSearchProps('connector'),
            },
            {
                title: 'dbidentifier',
                dataIndex: 'dbidentifier',
                key: 'dbidentifier',
                ...this.getColumnSearchProps('dbidentifier'),
            },
            {
                title: 'region',
                dataIndex: 'region',
                key: 'region',
                ...this.getColumnSearchProps('region'),
            },
        ];
        return <Table columns={columns} dataSource={this.state.tableData} />;
    }
}