import React, {useEffect, useState} from 'react'
import { Collapse , Button } from 'antd';
import * as AWS from 'aws-sdk'
import * as jmespath from 'jmespath';
import { Spin } from 'antd';
import "./Collapse.css"
const { Panel } = Collapse;

function createTsvHead(headings) {
  let tsvHead = '#\t'
  for (let h = 0; h < headings.length; h++) {
    if (typeof headings[h] === 'string') {
      tsvHead += headings[h]
    } else {
      tsvHead += Object.keys(headings[h])[0]
    }
    if (h < (headings.length - 1)) tsvHead += '\t'
  }
  tsvHead += '\n'
  return tsvHead
}

function createTsvBody(headings, data) {
  const rows = [];
  for (let d = 0; d < data.length; d++) {
    let tsvRow = `${d + 1}\t`
    for (let h = 0; h < headings.length; h++) {
      if (typeof headings[h] === 'string') {
        try {
          tsvRow += jmespath.search(data[d], headings[h])
        } catch (e) {
          console.error(`Error with jmespath: ${headings[h]}: ${e.message}`)
        }
      } else {
        try {
          tsvRow += jmespath.search(data[d], Object.values(headings[h])[0])
        } catch (e) {
          console.error(`Error with jmespath: ${Object.values(headings[h])[0]}: ${e.message}`)
        }
      }
      if (h < (headings.length - 1)) tsvRow += '\t'
    }
    rows.push(tsvRow)
  }
  return rows.join('\n')
}

function createTsvLink(fileTitle = 'export', tsv = '') {
  const fileName = fileTitle + '.tsv'
  const blob = new Blob([tsv], { type: 'text/tab-separated-values;charset=utf-8;' })
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.className = "btn btn-primary btn-sm";
  link.style.display = "none";
  link.textContent = `Download ${fileName}`
  if (link.download !== undefined) {
    link.setAttribute("download", fileName);
  }
  link.click();
}

// const stylePanel = {
//   backgroundColor: "#007bff",
//     borderColor: "#007bff",
//     color: "white !important",
//     fontSize: "1.875rem",
//     borderRadius: "0.2rem",
//     margin: "10px 0"
// }

const styleTab = {
  border: "none",
  color: "white !important"
}


function CustomCollapse({data}){
  const [loader, setLoader] = useState(true);
  const [apiData, setData] = useState({});
  // const getServiceData = () => {
  //   const startTs = Date.now()
  //   let caller;
  //   try {
  //     caller = new AWS[data?.query.service]({ region: data?.region });
  //   } catch (e) {
  //     console.log(`Error using query.service ${data?.query.service}: `, e.message)
  //     setData({error : true , message : e.message })
  //     setLoader(false)
  //   }
  //   if (data?.query.hasOwnProperty('params')) {
  //       caller[data?.query.api].call(caller, data?.query.params, (err, callbackData) => {
  //         if (err) {
  //           console.log("data?.query.api" , err.message)
  //           setData({error : true , message : err.message, time: (Date.now() - startTs)})
  //           setLoader(false)
  //           return false
  //         }
  //         let information
  //         try {
  //           information = jmespath.search(callbackData, `${data?.query.jmespath}[]`)
  //           setData({information : information, heading: data?.query?.headings , time : (Date.now() - startTs)})
  //           setLoader(false)
  //         } catch (e) {
  //           console.error(`Error with jmespath: ${data?.query.jmespath}[]: ${e.message}`)
  //           setData({error : true , message : e.message, time: (Date.now() - startTs)});
  //           setLoader(false);
  //         }
  //       })
  //   } else {
  //       try {
  //         caller[data?.query.api].call(caller , (err ,callbackData) => {
  //           if (err) {
  //             console.log("data?.query.api" , err.message)
  //             setData({error : true , message : err.message, time: (Date.now() - startTs)})
  //             setLoader(false)
  //             return false
  //           }
  //           let information
  //           try {
  //             information = jmespath.search(callbackData, `${data?.query.jmespath}[]`)
  //             setData({information : information , heading: data?.query?.headings , time: (Date.now() - startTs)})
  //             setLoader(false)
  //           } catch (e) {
  //             console.error(`Error with jmespath: ${data?.query.jmespath}[]: ${e.message}`)
  //             setLoader(false)
  //             setData({error : true , message : e.message, time: (Date.now() - startTs)})       
  //           }
  //         })
  //       } catch (e) {   
  //         setData({error : true , message : e.message, time: (Date.now() - startTs)})
  //         setLoader(false)
  //         console.error(`Error calling ${data?.query.service}.${data?.query.api}: ${e.message}`)
        
  //   }}
  // }
  useEffect(() => {
    const getServiceData = async () => {
      const startTs = Date.now()
      let caller;
      try {
        caller = new AWS[data?.query.service]({ region: data?.region });
      } catch (e) {
        console.log(`Error using query.service ${data?.query.service}: `, e.message)
        setData({error : true , message : e.message })
        setLoader(false)
      }
      if (data?.query.hasOwnProperty('params')) {
          caller[data?.query.api].call(caller, data?.query.params, (err, callbackData) => {
            if (err) {
              console.log("data?.query.api" , err.message)
              setData({error : true , message : err.message, time: (Date.now() - startTs)})
              setLoader(false)
              return false
            }
            let information
            try {
              information = jmespath.search(callbackData, `${data?.query.jmespath}[]`)
              setData({information : information, heading: data?.query?.headings , time : (Date.now() - startTs)})
              setLoader(false)
            } catch (e) {
              console.error(`Error with jmespath: ${data?.query.jmespath}[]: ${e.message}`)
              setData({error : true , message : e.message, time: (Date.now() - startTs)});
              setLoader(false);
            }
          })
      } else {
          try {
            caller[data?.query.api].call(caller , (err ,callbackData) => {
              if (err) {
                console.log("data?.query.api" , err.message)
                setData({error : true , message : err.message, time: (Date.now() - startTs)})
                setLoader(false)
                return false
              }
              let information
              try {
                information = jmespath.search(callbackData, `${data?.query.jmespath}[]`)
                setData({information : information , heading: data?.query?.headings , time: (Date.now() - startTs)})
                setLoader(false)
              } catch (e) {
                console.error(`Error with jmespath: ${data?.query.jmespath}[]: ${e.message}`)
                setLoader(false)
                setData({error : true , message : e.message, time: (Date.now() - startTs)})       
              }
            })
          } catch (e) {   
            setData({error : true , message : e.message, time: (Date.now() - startTs)})
            setLoader(false)
            console.error(`Error calling ${data?.query.service}.${data?.query.api}: ${e.message}`)
          
      }}
    }
    getServiceData();
  }, [data])
  const createTableHead = (headings) =>  {
    if(!headings){
      return [];
    }
    const tr = [];
    for (let h=0; h<headings.length; h++) {
      if (typeof headings[h] === 'string') {
        tr.push(headings[h])
      } else {
        tr.push(Object.keys(headings[h])[0])
      }

    }
    return tr
  }
  const createTableBody =(headings, tableData) => {
    const rows = []
    if(!headings || !tableData || !tableData.length){
      return [];
    }
    for (let d=0; d<tableData.length; d++) {
      const tr = []
      for (let h=0; h<headings.length; h++) {
        if (typeof headings[h] === 'string') {
          try {
            tr.push(jmespath.search(tableData[d], headings[h]))
            //tr.append($(`<td>${jmespath.search(data[d], headings[h])}</td>`))
          } catch (e) {
            console.error(`Error with jmespath: ${headings[h]}: ${e.message}`)
          }
        } else {
          try {
            tr.push(jmespath.search(tableData[d], Object.values(headings[h])[0]))
          } catch (e) {
            console.error(`Error with jmespath: ${Object.values(headings[h])[0]}: ${e.message}`)
          }
        }
      }
      rows.push(tr)
    }
    return rows
  }
  const exportTableToCSV = () => {
    let tsv = createTsvHead(data?.query?.headings)
    tsv += createTsvBody(data.query.headings , apiData.information)
    createTsvLink(data?.query?.id, tsv)
  }
  const getHeaderContent = () => {
    return <div id={(apiData.information &&apiData.information.length > 0) ? "ant-collapse-header-white" : "ant-collapse-header-black"}><span className="counter badge badge-light">{(apiData?.information?.length?.toString()) || 'x' }</span> {data?.query?.title} &mdash; {apiData.time && apiData?.time?.toString().length < 3 ? `${(apiData.time?.toString() + 'ms' || '')}` : `${(apiData.time)/1000 + 's' || ''}` || ''}</div>
  }
  return (
    <Collapse ghost={true} style={styleTab} defaultActiveKey={['1']}>
    <Panel className={(apiData.information) ? "ant-panel" : "ant-panel-warning"} showArrow={false} header={getHeaderContent()} key={data.region + Math.random()}>
      {loader && <div className="text-center"><Spin /></div>}
      {apiData && apiData.error && <div class="alert alert-danger show my-2" role="alert"><strong>Error:</strong> ${apiData?.message}</div>}
      {apiData && apiData.heading && apiData.information && apiData.information.length > 0 &&  <code>
        <table className="table table-bordered table-hover table-sm" id={data?.query?.id}>
          <thead class="thead-light">
            <tr>
              {createTableHead(apiData.heading).map(x => {
                return <td className="text-center">{x}</td>
              })}
            </tr>
          </thead>
          <tbody>
              {createTableBody(apiData.heading, apiData.information).map(trs => {
                return <tr>{trs.map(td => <td className="text-center">{Array.isArray(td) ? td[0] : td?.toString() }</td>)}</tr>
              })}
          </tbody>
        </table>
        {apiData.information && apiData.information.length > 0 && <Button  type="primary" onClick={exportTableToCSV} size={'large'}>
          {data?.query?.id + ".tsv"}
        </Button>}
      </code>}
    </Panel>
  </Collapse>
  )
}

export default CustomCollapse;