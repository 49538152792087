import React, { Component } from "react";
import {Auth} from "aws-amplify";
import { Col, Row} from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Tabs, Button } from 'antd';
import AWSIDLEAPIHelper from '../../../API/awsIdleAPIHelper';
import AWSConectorAPIHelper from '../../../API/awsConectorAPIHelper';
import ChatIcon from "../../../assets/images/chat.svg";
import "./style.css"

const { TabPane } = Tabs;

export default class IDLEEIP extends Component {
     constructor(props) {
      super(props);
      
       this.state = {
            statList : [],
            accountList: [],
            connectorList:[],
            AWS_REGIONS : [],
            regions: ["eu-north-1" , "ap-south-1" , "eu-west-3" , "eu-west-2" , "eu-west-1" , "ap-northeast-3" , "ap-northeast-2" , "ap-northeast-1" , "sa-east-1" , "ca-central-1" , "ap-southeast-1" , "ap-southeast-2" , "eu-central-1" , "us-east-1" , "us-east-2" , "us-west-1" , "us-west-2"],
            regionsData: {},
            forcastList:[],
            costTypeList: ['Cost Type'],
            connectorId : '',
            mode: 'top'

       } 
    }


    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
               console.log("User is logged-in");
               this.props.userHasAuthenticated(true);
               this.getConntectors();
                // this.credentialsVerify()
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );

    }

    getConntectors = async () => {
      this.props.setLoadingWheel(true);
      const connectors = await AWSConectorAPIHelper.getRoleArnList();
      this.setState({connectorList : connectors.data.body.map(x => x[1].stringValue)})
      this.props.setLoadingWheel(false);
    }

    onConnectorChange = (event) =>{
        this.setState({connectorId : event.target.value })
    }

    getData = () => {
        if(!this.state.connectorId){
            alert("Please select connector");
            return;
        }
        this.props.setLoadingWheel(true);
        AWSIDLEAPIHelper.IdleEIP(this.state.connectorId)
            .then((res) => {
                this.setState({regionsData : res.data.body})
                this.props.setLoadingWheel(false);

            })
            .catch(() => {
              this.props.setLoadingWheel(false);
            })
    }
    render(){
        return (
           <div>
               <div>
               <div className=" SATopChat">
                    <img src={ChatIcon} alt="chat" className="SAChatIcon" />
                    <span className="my-0 SAChatPara">
                     Checks and identifies unassociated EIP
                    </span>
                </div>
               <Row>
                   <Col span={4}>
                        <Row>
                            <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>                              
                                <select className='select-dash' defaultValue='AWS' disabled style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    
                                    <option value="AWS">AWS</option>
                                </select>
                              
                            </div>
                        </Row>
                    </Col>
                     <Col span={8}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.connectorId} onChange={this.onConnectorChange} style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    <option key={"select Connector Name"} value={"select Connector Name"}>Select Connector Name</option>
                                    
                                    {this.state.connectorList.map(account => {
                                         return (
                                         <option key={account} value={account}>{account}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={this.getData} shape="round" size={'large'}>
                            AWS IDLE Scan
                      </Button>
                    </Col>
               </Row>
               </div>
               <br/>
               <br/>
               <div>
        <Tabs defaultActiveKey="1" tabPosition={"left"}>
            {Object.keys(this.state.regionsData).length > 0 &&this.state.regions.map(region => (
              <TabPane tab={region} key={region}>
                    <Row>
                        <Col span={4}><h5>Service</h5></Col>
                        <Col span={4}><h5>Service Name</h5></Col>
                        <Col span={4}><h5>Instane-ID</h5></Col>
                        <Col span={4}><h5>Status</h5></Col>
                        <Col span={4}><h5>Reason</h5></Col>
                    </Row>
                    <Row>
                        <Col span={4}>{this.state.regionsData[`Region: ${region}`] &&this.state.regionsData[`Region: ${region}`][0].split(" ")[1]}</Col>
                        <Col span={4}>{this.state.regionsData[`Region: ${region}`] &&this.state.regionsData[`Region: ${region}`][1].split(" ")[1]}</Col>
                        <Col span={4}>{this.state.regionsData[`Region: ${region}`] &&this.state.regionsData[`Region: ${region}`][2].split(" ")[1]}</Col>
                        <Col span={4}>{this.state.regionsData[`Region: ${region}`] &&this.state.regionsData[`Region: ${region}`][3].split(" ")[1]}</Col>
                        <Col span={4}>{this.state.regionsData[`Region: ${region}`] &&this.state.regionsData[`Region: ${region}`][4].substring(7)}</Col>

                    </Row>
                  {/* {JSON.stringify(this.state.regionsData[`Region: ${region}`] &&this.state.regionsData[`Region: ${region}`] || '')} */}
              </TabPane>
            ))}
        </Tabs>
      </div>
           </div>

        )
    }

}