import axios from 'axios';

class AWSInventoryAPIHelper{
    static async getCrednetials(connectorname)
    {
        
        return await axios.post(`https://vmy1e9t4k2.execute-api.eu-west-1.amazonaws.com/Prod/tempcall` ,{
            "authuseremail": localStorage.getItem('email'),
            connectorname
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }


}

export default AWSInventoryAPIHelper;