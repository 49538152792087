import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Auth} from "aws-amplify";
import {Logger} from "@aws-amplify/core";

const GoogeCloud = (props) => {
  const logger = new Logger("Azure");
  const history = useHistory();

        useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                history.push("/")
            else{
                logger.log("User is logged-in");
                props.userHasAuthenticated(true);
            }
        }).catch(err => {
                logger.log("Couldn't get the logged-in user for some reason: " + err);
                props.userHasAuthenticated(false);
                history.push("/")
            }
        );
    })
  return (
    <>
      <div>
        <div className="input-items">
          <div>
            <legend>
              <b>Select Provider</b>
            </legend>
            <select className="in">
              <option>Azure</option>
              <option selected>Google Cloud</option>
            </select>
          </div>
          <div className="connector">
            <legend>
              <b>Connector Name</b>
            </legend>
            <input className="inp" />
          </div>
        </div>
        <br />
        <input
          type="checkbox"
          id="resource-scheduling"
          name="resource-scheduling"
          value="resource-scheduling"
          className="chkbx"
        />
        <label htmlFor="resource-scheduling">
          Configure as Billing Connector
        </label>
      </div>
      <br />
      <hr />
      <div className="description">
        <p>
          <b>Description</b>
        </p>
        <p className="paragraph">
          Google Cloud Connector is used on your Google Cloud account to
          retrieve data. For each service that need to be enabled for Google
          Cloud, a specific set of permissions are required. Click here to
          identify the IAM priviliges that are required for each service.
        </p>
      </div>
      <br />
      <hr />
      <div>
        <p>
          <b>GCP Connector Credentials</b>
        </p>
        <br />
        <form>
          <textarea
            rows="8"
            cols="110"
            placeholder=" Copy the credetial Payload from GCP console and paste here"
          />
        </form>
      </div>
      <br />
      <hr />
      <p>
        {" "}
        <b>GCP Connector Details</b>
        <div className="credentials">
          <div>
            <input
              className="inpp"
              placeholder="Google Cloud Organization ID"
            />
          </div>
          <div className="">
            <input className="inpp" placeholder="Google Billing Account ID" />
          </div>
          <div>
            <input className="inpp" placeholder="Billing Dataset Name" />
          </div>
          <div>
            <input className="inpp" placeholder="Billing Table" />
          </div>
        </div>
      </p>
      <hr />
      <br />
      <br />
      <br />
      <div className="btn">
        <button type="" className="cancel">
          Cancel
        </button>
        <button type="" className="submit" disabled>
          Submit
        </button>
      </div>
      <br/>
      <br/>
      <style jsx="true">
        {`
          * {
            font-family: "Open Sans", sans-serif;
          }

          select {
            all: unset;
            width: 250px;
            border-bottom: 1px solid cyan;

            background: url("https://www.charbase.com/images/glyph/9662")
              no-repeat right;

            background-size: 16px;
          }

          .inp {
            border: none;
            border-bottom: 1px solid orange;
            padding-right: 100px;
            outline: none;
          }

          .input-items {
            display: flex;
            margin-top: 20px;
          }
          .connector {
            margin-left: 80px;
          }

          textarea {
            border: 1px solid #888;
            border-radius: 5px;
            margin-top: 10px;
            outline: none;
            overflow: auto;
          }
          textarea::placeholder {
            padding: 10px;
            font-size: 15px;
          }

          .inpp {
            border: 0;
            border-bottom: 1px solid;
            padding: 5px 0px;
            width: 250px;
            outline: 0;
          }

          [placeholder]:focus::-webkit-input-placeholder {
            transition: text-indent 0.4s 0.4s ease;
            text-indent: -80%;
            opacity: 1;
          }

          .credentials {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .btn {
            float: right;
            margin-right: 100px;
          }

          .cancel {
            margin-right: 200px;
            background-color: orange;
            border: none;
            border-radius: 5px
            color: white;
            padding: 10px 25px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 10px;
          }

          .submit {
            background-color: #bebdb8;
            border: none;
            border-radius: 5px
            color: white;
            padding: 10px 25px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 10px;
          }

          @media only screen and (max-width: 600px) {
            .check-form {
                display: block
                
            }

            .input-items {
                display: block;
                padding: 0
            }

            .btn {
                display: flex;
                justify-content: space-between;
                margin-right: 0px
            }
            input[type="checkbox"] {
                padding: 10px;
            }

            hr {
                width: 400px;
                border-left: 0px;
                border-right: 0px;
            }

            textarea {
                width: 100%;
                height: 100%;
            }

          }
        `}
      </style>
    </>
  );
};

export default GoogeCloud;
