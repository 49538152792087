import axios from 'axios';
import config from "../configs/aws-configs";

class AWSConectorAPIHelper{
    static async get()
    {
        let url = config.aws_api_gateway;
        
        return await axios.get(url + 'awsconnectorview?authuseremail=' + localStorage.getItem('email')  )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async getRoleArnList()
    {
        return await axios.get('https://htzc9hmtkl.execute-api.eu-west-1.amazonaws.com/Prod/awsconnectorviewrole?authuseremail=' + localStorage.getItem('email')  )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async create(access_key, secret_key, name)
    {
         let url = 'https://vnqocdhadh.execute-api.eu-west-1.amazonaws.com/Prod/';
         let data = {
            access_key_input: access_key,
            secret_key_input: secret_key,
            account_name_input: name,
            authuseremail: localStorage.getItem('email')
         }

         return await axios.post(url + 'awsconnect', data )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }

    static async createRoleArn(externalId, roleArn, name)
    {
         let url = 'https://3yls0ovww7.execute-api.eu-west-1.amazonaws.com/Prod/';
         let data = {
            externalid: externalId,
            rolearn: roleArn,
            account_name_input: name,
            authuseremail: localStorage.getItem('email')
         }

         return await axios.post(url + 'awsconnect', data )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }

    static async delete(name)
    {
         let url = 'https://v99oj7mvv5.execute-api.eu-west-1.amazonaws.com/Prod/awsconnectordel';
         let data = {
            connectorname: name,
            authuseremail: localStorage.getItem('email')
         }

         return await axios.post(url , data )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }

    static async deleteRoleArn(name)
    {
         let url = 'https://me21kkw667.execute-api.eu-west-1.amazonaws.com/Prod/awsconnectordel';
         let data = {
            connectorname: name,
            authuseremail: localStorage.getItem('email')
         }

         return await axios.post(url , data )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }

    static async getSummary(name)
    {
         let url = 'https://2dylbkie7k.execute-api.eu-west-1.amazonaws.com/Prod/idlesummary';
         let data = {
            connector: name,
            useremail: localStorage.getItem('email')
         }

         return await axios.post(url , data )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }

    
}

export default AWSConectorAPIHelper;