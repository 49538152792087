import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Auth} from "aws-amplify";
import {Logger} from "@aws-amplify/core";

const Azure = (props) => {
  const logger = new Logger("Azure");

  const history = useHistory();

        useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                history.push("/")
            else{
                logger.log("User is logged-in");
                props.userHasAuthenticated(true);
            }
        }).catch(err => {
                logger.log("Couldn't get the logged-in user for some reason: " + err);
                props.userHasAuthenticated(false);
                history.push("/")
            }
        );
    })
    
  return (
    <>
      <div>
        <p>Configure Cloud Platform Connector</p>
        <hr />
        <div className="input-items">
          <div>
            <legend>
              <b>Select Provider</b>
            </legend>
            <select className="in">
              <option>Azure</option>
              <option>Google Cloud</option>
            </select>
          </div>
          <div className="connector">
            <legend>
              <b>Connector Name</b>
            </legend>
            <input className="inp" />
          </div>
        </div>
        <br />
        <hr />
        <div className="description">
          <p>
            <b>Description</b>
          </p>
          <p className="paragraph">
            Azure Connection is used to connect to your Azure account to
            retrieve data. For each service that need to be enabled for Azure a
            specific set of permissions are required. Click here to identify the
            IAM privileges that are required for each service. The system will
            validate whether the right set of permissions are available for the
            services selected by the user.
          </p>
        </div>
        <br />
        <hr />
        <legend>
          {" "}
          <b>Azure Conector Credentials</b>{" "}
        </legend>
        <br />
        <div className="credentials">
          <div>
            <input className="inpp" placeholder="Azure Client Id*" />
          </div>
          <div className="connector">
            <input className="inpp" placeholder="Azure Secret Key*" />
          </div>
        </div>
        <br />
        <hr />
        <legend>
          {" "}
          <b>Azure Conector Details</b>{" "}
        </legend>
        <br />
        <div className="credentials">
          <div>
            <input className="inpp" placeholder="Azure Tenant Id" />
          </div>
          <div className="connector">
            <input className="inpp" placeholder="Azure Subscription Id" />
          </div>
        </div>
        <br />
        <hr />
        {/* <legend>
          {" "}
          <b>Select Services To Be Enabled</b>{" "}
        </legend> */}
        {/* <br />
        <form className="check-form">
          <div>
            <input
              type="checkbox"
              id="Cost-Query"
              name="Cost-Query"
              value="Cost Query"
              classNam="chkbx"
            />
            <label htmlFor="Cost-Query"> Cost Query</label>
            <i className="fas fa-info-circle info"></i>
          </div>

          <div>
            <input
              type="checkbox"
              id="recommendations"
              name="recommendations"
              value="recommendations"
              classNam="chkbx"
            />
            <label htmlFor="recommendations"> Recommendations</label>
            <i className="fas fa-info-circle info"></i>
          </div>

          <div>
            <input
              type="checkbox"
              id="policy"
              name="policy"
              value="policy"
              className="chkbx"
            />
            <label htmlFor="policy">Policy Execution</label>
            <i className="fas fa-info-circle info"></i>
          </div>
          <div>
            <input
              type="checkbox"
              id="resource-scheduling"
              name="resource-scheduling"
              value="resource-scheduling"
              className="chkbx"
            />
            <label htmlFor="resource-scheduling">Resource Scheduling</label>
            <i className="fas fa-info-circle info"></i>
          </div>
          <br />
          <br />
        </form>
        <br /> */}
        <br />
        <br />
        <div className="btn">
          <button type="" className="cancel">
            Cancel
          </button>
          <button type="" className="submit" disabled>
            Submit
          </button>
        </div>
      </div>
      <br/>
      <br/>
      <style jsx="true">
        {`

        * {
             font-family: 'Open Sans', sans-serif;
        }
          select {
            all: unset;
            width: 250px;
            border-bottom: 1px solid cyan;

            background: url("https://www.charbase.com/images/glyph/9662")
              no-repeat right;

            background-size: 16px;
          }

          .paragraph {
              font-style: italic;
              color: grey;
          }

          .inp {
            border: none;
            border-bottom: 1px solid orange;
            padding-right: 100px;
            outline: none;
          }

          .input-items {
            display: flex;
            
            margin-top: 20px;
          }

          .connector {
            margin-left: 80px;
          }

          .inpp {
            border: 0;
            border-bottom: 1px solid;
            padding: 5px 0px;
            width: 250px;
            outline: 0;
          }

          [placeholder]:focus::-webkit-input-placeholder {
            transition: text-indent 0.4s 0.4s ease;
            text-indent: -80%;
            opacity: 1;
            
          }

          .credentials {
            display: flex;
          }

          .check-form {
            display: flex;
            justify-content: space-between;
          }

          .info {
            color: #56badb;
            margin-left: 3px;
            position: relative;
            bottom: 10px;
          }

          .btn {
            float: right;
            margin-right: 100px;
          }

          .cancel {
            margin-right: 200px;
            background-color: orange;
            border: none;
            border-radius: 5px
            color: white;
            padding: 10px 25px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 10px;
          }

          .submit {
            background-color: #bebdb8;
            border: none;
            border-radius: 5px
            color: white;
            padding: 10px 25px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 10px;
          }

          @media only screen and (max-width: 600px) {
            .check-form {
                display: block
                
            }

            .btn {
                display: flex;
                justify-content: space-between;
                margin-right: 0px
            }
            input[type="checkbox"] {
                padding: 10px;
            }

            hr {
                width: 600px;
                border-left: 0px;
                border-right: 0px;
            }

          }
        `}
      </style>
    </>
  );
};

export default Azure;
