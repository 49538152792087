import React, { Component } from "react";
import {Auth} from "aws-amplify";
import {Table, Col, Row, Card} from 'antd';
import statisticAPIHelper from '../../API/statisticAPIHelper';
import ReactApexCharts from 'react-apexcharts'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
//import UserManagement from '../../components/user/userManagement'

export default class Dashboard extends Component {
     constructor(props) {
      super(props);
      
       this.state = {
            statList : [],
            accountList: [],
            connectorList:[],
            forcastList:[],
            costTypeList: ['Cost Type'],
            perviousMonthStartDate: new Date(),
            currentMonthStartDate : new Date(),
            perviousMonthEndDate: new Date(),
            currentMonthEndDate : new Date(),
            accountId : '-1',
            connectorId : '-1',
            costType : '',
            serviceList : [],
            calculatedServiceList : [],
            lastMonthTotalCost: 0.00,
            previousMonthTotalCost : 0.00,
            currentMonthTotalCost:0.00,
            perviousMonthLabel :'',
            currentMonthLabel :'',
            startDate : new Date(),
            endDate : new Date(),
            topFiveServices : [],
            serviceBalanceList :[],
            topFiveServiceList:[],
            selectedDateRange :[],
            barChartSeries: [],
            isPieChartDisplay : false,
            isBarChartDisplay: false,
            isMonthlyChartDisplay : false,
            monthlyStatList : [],
            monthlyForcast:0.00,
            threeMonthsForcast:0.00,
            twelMonthsForcast:0.00,
            barChartOptions: 
            {
                chart: {
                  type: 'bar',
                  height: 250,
                  width: 470
                },
                plotOptions: {
                  bar: {
                    borderRadius: 15
                  }
                },
                dataLabels: {
                  enabled: false
                },
                xaxis: {
                  categories: [],
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false
                    }
                  }
                },
                yaxis: {
                  axisTicks: {
                    show: true
                  },
                  labels: {
                    formatter: function (value) {
                      // if(value > 10000)
                      //   return '$' + value/1000 + "K";
                      // else
                        return '$' + value

                    }
                  },
                },
                title: {
                  text: 'Cost - Last Months',
                  align: 'left',
                  margin: 10,
                  offsetX: 0,
                  offsetY: 0,
                  floating: false,
                  style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#263238'
                  },
                }
              },
              pieChartSeries: [],
              pieChartOptions: {
              chart: {
                width: 464,
                height: 250,
                type: 'donut',
              },
              labels: [],
              tooltip: {
                y: {
                  formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return '$' + value
                  }
                }
              },
              legend:{
                position: 'bottom',
                itemMargin: {
                  horizontal: 10,
                  vertical: 5
              },
              },
              title: {
                text: 'Top 5 Services by Cost',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize:  '14px',
                  fontWeight:  'bold',
                  fontFamily:  undefined,
                  color:  '#263238'
                },
              }
            },  
            serviceBarChartSeries:  [],
            serviceBarChartOptions: 
            {
                chart: {
                  type: 'bar',
                  height: 250,
                  width: 470
                },
                colors: ["#02E675",
                "#FF9A00",
                "#75E7FF",
                "#002438",
                "#785F9D"],
                plotOptions: {
                  bar: {
                    columnWidth: '75%',
                    distributed: true,
                  }
                },
                legend: {
                  show: true,
                  position: 'bottom',
                //   itemMargin: {
                //     horizontal: 10,
                //     vertical: 5
                // },
                },
                dataLabels: {
                  enabled: false
                },
                xaxis: {
                  categories: [],
                  labels: {
                    rotate: 0
                  }
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false
                    }
                  }
                },
                yaxis: {
                  axisTicks: {
                    show: true
                  },
                  labels: {
                    formatter: function (value) {
                      // if(value > 10000)
                      //   return '$' + value/1000 + "K";
                      // else
                        return '$' + value

                    }
                  },
                },
                title: {
                  text: 'Top 5 Spending by Services',
                  align: 'left',
                  margin: 10,
                  offsetX: 0,
                  offsetY: 0,
                  floating: false,
                  style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#263238'
                  },
                }
              },
              monthlyCostBarChartSeries:[],
              monthlyCostBarChartOptions: 
            {
                chart: {
                  type: 'bar',
                  height: 250,
                  width: 470,
                  offsetY: 0
                },
                plotOptions: {
                  bar: {
                    borderRadius: 15
                  }
                },
                dataLabels: {
                  enabled: false
                },
                xaxis: {
                  categories: [],
                  labels: {
                    rotate: 0
                  }
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false
                    }
                  }
                },
                yaxis: {
                  axisTicks: {
                    show: true
                  },
                  labels: {
                    formatter: function (value) {
                      // if(value > 10000)
                      //   return '$' + value/1000 + "K";
                      // else
                        return '$' + value

                    }
                  },
                },
                title: {
                  text: 'Previous Months to Date Cost',
                  align: 'left',
                  margin: 10,
                  offsetX: 0,
                  offsetY: 0,
                  floating: false,
                  style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#263238'
                  },
                }
              },

       } 

       this.columns = [
        {
          title: 'Product Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Cost',
          dataIndex: 'sum',
          key: 'sum',
          render: (value, row, index) => {
            return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        }]
    }

    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
             //   logger.log("User is logged-in");
                this.props.userHasAuthenticated(true);
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() -6);
        this.setState({startDate : fromDate, endDate : toDate})
       
        // if(toMonth === fromMonth)
         // this.getCostAndUsageDailyData(new Date(fromDate.getFullYear(), fromDate.getMonth() - 6, 2).toISOString().substring(0, 10), toDate.toISOString().substring(0, 10));
        // else
          this.getCostAndUsageDailyData(moment(fromDate).format().substring(0, 10), moment(toDate).format().substring(0, 10));

         // this.getCostAndUsageDailyMonthlyData();

    }

    getForcastData = () =>{
       
      this.props.setLoadingWheel(true);
      this.setState({monthlyForcast : 0.00, threeMonthsForcast:0.00, twelMonthsForcast : 0.00}, () => {
      statisticAPIHelper.getForcastData()
      .then(res => {
          if(res.status === 200){
                let forcastList = [];    
              if(res.data.body.length > 0){
                  res.data.body.forEach(element => {
                      
                  let item = {
                      connectorName : element[0].stringValue,
                      date : element[2].stringValue,
                      twlMonthsForcast : element[3].stringValue,
                      threeMonthsForcast	 : element[4].stringValue,
                      monthlyForcast : element[5].stringValue,
                      costType : element[6].stringValue
                  }
                  
                  forcastList.push(item);
                  
              })
                  this.setState({forcastList : forcastList}, () => this.processForcast());
                  this.props.setLoadingWheel(false);
              }
              else{
                  this.props.setLoadingWheel(false);
              }
          }
           else{
              this.props.setLoadingWheel(false);
          }
      }).catch(() => {
        this.props.setLoadingWheel(false);
      })
    });
  }

  formatToDate(dateString) {
    var d = new Date();
    dateString = dateString.split('-');
    d.setFullYear(dateString[0]);
    d.setMonth(dateString[1]);
    d.setDate(dateString[2]);
    return d;
  }


  processForcast = () => {
    let generatedCostType = '';
    switch(this.state.costType){
      case 'UnblendedCost' :
        generatedCostType = 'UNBLENDED_COST';
        break;
      case 'BlendedCost' :
        generatedCostType = 'BLENDED_COST';
        break;
      case 'NetAmortizedCost':
        generatedCostType = 'NET_AMORTIZED_COST'
        break;
      case 'NetUnblendedCost':
        generatedCostType = 'NET_UNBLENDED_COST'
        break;
      case 'AmortizedCost':
        generatedCostType ='AMORTIZED_COST'
        break;
      default:
        generatedCostType = '';
    }

    let selectedForcastData = this.state.forcastList.filter(item =>  {return (this.state.connectorId === '-1' ? true: item.connectorName === this.state.connectorId) &&( this.state.costType === 'Cost Type' ? true : item.costType === generatedCostType)});

    let latestData = null;
    if(selectedForcastData.length > 0){
      if(selectedForcastData.length > 1){
        latestData = selectedForcastData[0];
        for(var i = 1; i < selectedForcastData.length; i++) {
          if (this.formatToDate(selectedForcastData[i].date) > this.formatToDate(latestData.date))
          latestData = selectedForcastData[i];
        }
  
      }
      else
       latestData = selectedForcastData[0];
       this.setState({monthlyForcast : (Math.round(latestData.monthlyForcast * 100 ) / 100), threeMonthsForcast : (Math.round(latestData.threeMonthsForcast * 100 ) / 100), twelMonthsForcast : (Math.round(latestData.twlMonthsForcast * 100 ) / 100) });      
    }
    else {
      this.setState({monthlyForcast : 0.00, threeMonthsForcast : 0.00, twelMonthsForcast : 0.00 })
    }
    
      
  }

    getCostAndUsageDailyData = (fromDate, toDate) =>{
       
        this.props.setLoadingWheel(true);
        const newObj = { ...this.state.pieChartOptions, labels: [] } // create a new object by spreading in the this.state.car and overriding features with our new array 
                    this.setState({ pieChartOptions: newObj, pieChartSeries: [], isPieChartDisplay : false, isBarChartDisplay : false })
        this.setState({barChartSeries : [], reverseBarChartSeries:[], perviousMonthTotalCost : 0.00, currentMonthTotalCost :0.00, statList : [], topFiveServiceList: [], calculatedServiceList :[]}, () => {
        statisticAPIHelper.get(fromDate, toDate)
        .then(res => {
            if(res.status === 200){
                  let statList = [];    
                if(res.data.body.records.length > 0){
                    res.data.body.records.forEach(element => {
                        
                    let item = {
                        accountId : element[0].stringValue,
                        service: element[1].stringValue,
                        cost : element[2].stringValue,
                        start_timeperiod : new Date(element[3].stringValue).setHours(0,0,0,0),
                        end_timeperiod	 : new Date(element[4].stringValue).setHours(0,0,0,0),
                        cost_type : element[5].stringValue,
                        accountname : element[6].stringValue,
                        date : element[7].stringValue,
                        start_month : new Date(element[3].stringValue).getMonth(),
                        end_month : new Date(element[4].stringValue).getMonth(),
                    }
                    
                    statList.push(item);
                    
                })
                    this.setState({statList : statList}, () => { this.processDateRange()})
                    this.props.setLoadingWheel(false);
                }
                else{
                    this.props.setLoadingWheel(false);
                }
            }
             else{
                this.props.setLoadingWheel(false);
            }
        }).catch(() => {
          this.props.setLoadingWheel(false);
        })
      });
    }

    getCostAndUsageDailyMonthlyData = () =>{
//      debugger
      this.props.setLoadingWheel(true);
      this.setState({perviousMonthTotalCost : 0.00, currentMonthTotalCost :0.00, monthlyStatList : []})
      // console.log("dfg" , moment)
      
      statisticAPIHelper.get(moment(new Date(new Date().getFullYear(), new Date().getMonth()-2,1)).format().substring(0, 10), moment(new Date()).format().substring(0, 10))
      .then(res => {
          if(res.status === 200){
                let statList = [];
                let startDate = new Date();
                let endDate = new Date();    
              if(res.data.body.records.length > 0){
                  res.data.body.records.forEach(element => {
                      startDate = new Date(new Date(element[3].stringValue).getFullYear(), new Date(element[3].stringValue).getMonth(), new Date(element[3].stringValue).getDate());
                      endDate = new Date(new Date(element[4].stringValue).getFullYear(), new Date(element[4].stringValue).getMonth(), new Date(element[4].stringValue).getDate());
                      let item = {
                      accountId : element[0].stringValue,
                      service: element[1].stringValue,
                      cost : element[2].stringValue,

                      start_timeperiod : startDate,
                      end_timeperiod	 : endDate,
                      cost_type : element[5].stringValue,
                      accountname : element[6].stringValue,
                      date : element[7].stringValue,
                      start_month : new Date(element[3].stringValue).getMonth(),
                      end_month : new Date(element[4].stringValue).getMonth(),
                  }
                  
                  statList.push(item);
                  
              })
                  this.setState({monthlyStatList : statList}, () => { this.calculateMonthlyCost()})
                  this.props.setLoadingWheel(false);
              }
              else{
                  this.props.setLoadingWheel(false);
              }
          }
           else{
              this.props.setLoadingWheel(false);
          }
      }).catch(() => {
        this.props.setLoadingWheel(false);
      })
  }

     processDataSet = (statList) => {
        let processedAccountList = [];
        let processedServiceList = [];
        let processedConnectorList = [];
        processedAccountList.push({id : '-1', name : 'Connector Name'});
        processedConnectorList.push({id : '-1', name : 'Connector Name'});
        let processedCostType = [];
        processedCostType.push('Cost Type');
        //  processedCostType.push('Unblended');
        statList.forEach(ele => {
          let account =  processedAccountList.filter(acc => acc.id === ele.accountId);
          if(account.length === 0)
            processedAccountList.push({id : ele.accountId, name : ele.accountname});

          let connector =  processedConnectorList.filter(acc => acc.id === ele.accountname);
          if(connector.length === 0)
          processedConnectorList.push({id : ele.accountname, name : ele.accountname});


          let costtype = processedCostType.filter(type => type === ele.cost_type);
          if(costtype.length ===0)
            processedCostType.push(ele.cost_type);       
            

          let service = processedServiceList.filter(service => service.name === ele.service);
          if(service.length ===0)
            processedServiceList.push({name : ele.service, sum : 0.00});
        })

        let isUnblendedCostAvailable = processedCostType.find(item => item === 'UnblendedCost');
        if(isUnblendedCostAvailable)
          this.setState({costType : 'UnblendedCost'});
        else
          this.setState({costType : processedCostType[0]});

        this.setState({accountList : processedAccountList, connectorList : processedConnectorList, costTypeList : processedCostType, serviceList : processedServiceList}, () => {this.filterData(); this.getCostAndUsageDailyMonthlyData(); this.getForcastData()});
    }


    processMonthlyDataSet = (monthlyList) => {
      let processedAccountList = [];
      let processedServiceList = [];
      let processedConnectorList = [];
      processedAccountList.push({id : '-1', name : 'Connector Name'});
      processedConnectorList.push({id : '-1', name : 'Connector Name'});
      let processedCostType = [];
      processedCostType.push('Cost Type');
      //  processedCostType.push('Unblended');
      monthlyList.forEach(ele => {
        let account =  processedAccountList.filter(acc => acc.id === ele.accountId);
        if(account.length === 0)
          processedAccountList.push({id : ele.accountId, name : ele.accountname});

        let connector =  processedConnectorList.filter(acc => acc.id === ele.accountname);
        if(connector.length === 0)
        processedConnectorList.push({id : ele.accountname, name : ele.accountname});


        let costtype = processedCostType.filter(type => type === ele.cost_type);
        if(costtype.length ===0)
          processedCostType.push(ele.cost_type);       
          

        let service = processedServiceList.filter(service => service.name === ele.service);
        if(service.length ===0)
          processedServiceList.push({name : ele.service, sum : 0.00});
      })

      let isUnblendedCostAvailable = processedCostType.find(item => item === 'UnblendedCost');
      if(isUnblendedCostAvailable)
        this.setState({costType : 'UnblendedCost'});
      else
        this.setState({costType : processedCostType[0]});

      this.setState({accountList : processedAccountList, connectorList : processedConnectorList, costTypeList : processedCostType, serviceList : processedServiceList}, () => {this.calculateMonthlyCost()});
  }

    calculateMonthlyCost = () => {
      this.setState({monthlyCostBarChartSeries: []}, () => {
        let lastMonthStatList = this.state.monthlyStatList.filter(item => {return item.start_timeperiod >= new Date(new Date().getFullYear(), new Date().getMonth()-1,1)
          && item.end_timeperiod <= new Date(new Date().getFullYear(), new Date().getMonth() , 1)
          && (this.state.accountId === '-1' ? true : item.accountId === this.state.accountId) 
          && (this.state.costType === 'Cost Type' ? true : item.cost_type === this.state.costType)
          && (this.state.connectorId === '-1' ? true : item.accountname === this.state.connectorId) 
        });
        let previousMonthStatList = this.state.monthlyStatList.filter(item => {return item.start_timeperiod >= new Date(new Date().getFullYear(), new Date().getMonth()-2,1) 
            && item.end_timeperiod <= new Date(new Date().getFullYear(), new Date().getMonth() -1 , 1)
            && (this.state.accountId === '-1' ? true : item.accountId === this.state.accountId) 
            && (this.state.costType === 'Cost Type' ? true : item.cost_type ===this.state.costType)
            && (this.state.connectorId === '-1' ? true : item.accountname === this.state.connectorId) 
          });
        
        let currentMonthStatList = this.state.monthlyStatList.filter(item => {return item.start_timeperiod >= new Date(new Date().getFullYear(), new Date().getMonth(),1) 
            && item.end_timeperiod <= new Date()
            && (this.state.accountId === '-1' ? true : item.accountId === this.state.accountId) 
            && (this.state.costType === 'Cost Type' ? true : item.cost_type ===this.state.costType)
            && (this.state.connectorId === '-1' ? true : item.accountname === this.state.connectorId) 
          });

          let lastMonthTotalCost = 0.00;
          let previousMonthTotalCost = 0.00
          let currentMonthTotalCost = 0.00
          let monthlyDataList = [];
          let monthlyLabelList =[];
//          debugger
          if(lastMonthStatList.length > 0){
               const values = lastMonthStatList.map(obj => obj.cost);
               lastMonthTotalCost = values.reduce((accumulator, currentValue) => { return (Math.round(( parseFloat(accumulator) + Number.EPSILON) * 100) / 100 ) +  (Math.round((parseFloat(currentValue) + Number.EPSILON) * 100) / 100); } , 0);
               this.setState({lastMonthTotalCost : lastMonthTotalCost});
          }
         
          if(previousMonthStatList.length > 0)
           {
             const values = previousMonthStatList.map(obj => obj.cost);
             previousMonthTotalCost = values.reduce((accumulator, currentValue) => { return (Math.round((parseFloat(accumulator) + Number.EPSILON) * 100) / 100 )+  (Math.round((parseFloat(currentValue) + Number.EPSILON) * 100) / 100);  } , 0);
             this.setState({previousMonthTotalCost : previousMonthTotalCost});
           }

           if(currentMonthStatList.length > 0)
           {
             const values = currentMonthStatList.map(obj => obj.cost);
             currentMonthTotalCost = values.reduce((accumulator, currentValue) => { return (Math.round((parseFloat(accumulator) + Number.EPSILON) * 100) / 100 )+  (Math.round((parseFloat(currentValue) + Number.EPSILON) * 100) / 100);  } , 0);
             this.setState({currentMonthTotalCost : currentMonthTotalCost});
            }

           if(previousMonthTotalCost > 0 || currentMonthTotalCost > 0 || lastMonthTotalCost > 0)
           this.setState({isMonthlyChartDisplay : true});

           monthlyDataList = [(Math.round(previousMonthTotalCost *100 ) / 100) , (Math.round(lastMonthTotalCost *100 ) / 100),(Math.round(currentMonthTotalCost *100 ) / 100)];
           monthlyLabelList = [['Previous','Month','Cost'], ['Last','Month','Cost'], ['Current','Month','To' , 'Date' , 'Cost']];

           
           this.setState({monthlyCostBarChartSeries:[]}, () => {
                    
            let array = [{
              data : monthlyDataList
            }]

            this.setState({monthlyCostBarChartSeries : [...array]});
            this.updateMonthlyCostBarChartCategories(monthlyLabelList);
          });
        });
    }

    filterData =() => {
     
      this.setState({pieChartSeries: [], barChartSeries : [], reverseBarChartSeries:[], topFiveServiceList: []}, () => {
        
        let givenRangeStatList = this.state.statList.filter(item => {return (this.state.accountId === '-1' ? true : item.accountId === this.state.accountId) 
                                                                            && (this.state.costType === 'Cost Type' ? true : item.cost_type === this.state.costType)
                                                                            && (this.state.connectorId === '-1' ? true : item.accountname === this.state.connectorId) 
                                                                          });
       


         //need calculation here
         let monthlyCost = 0.00;
         let selectedItems = [];
         let barChartData =[];
         let barChartLables =[];         
         let isBarChartDisplay = false;
         
         this.state.selectedDateRange.forEach(selected => {
          selectedItems = givenRangeStatList.filter(item => {return item.start_timeperiod >= selected.startVal
                                                  && (item.end_timeperiod <= new Date( selected.endVal.getFullYear(), selected.endVal.getMonth()+ 1, 1) )
                                                });
          
          if(selectedItems.length > 0){
              const values = selectedItems.map(obj => obj.cost);
              monthlyCost = values.reduce((accumulator, currentValue) => { return (Math.round(( parseFloat(accumulator) + Number.EPSILON) * 100) / 100 ) +  (Math.round((parseFloat(currentValue) + Number.EPSILON) * 100) / 100); } , 0);
            }
            else{
              monthlyCost = 0.0;
            }


            
            isBarChartDisplay = true;
            barChartData.push(Math.round(monthlyCost * 100 ) / 100);
            barChartLables.push(this.dateToYM(selected.startVal));

            

         })

         this.setState(({barChartSeries}) => ({
          barChartSeries: [
              ...barChartSeries.slice(0,1),
              {
                  ...barChartSeries[1],
                  data: barChartData,
              },
              ...barChartSeries.slice(2)
          ]
      }));

      this.setState({isBarChartDisplay : isBarChartDisplay});

      this.updateCategories(barChartLables);
         

         
       

         
         this.state.serviceList.forEach(service => {
             let serviceList = givenRangeStatList.filter(item => item.service === service.name);
             const values = serviceList.map(obj => obj.cost);
             const sum = values.reduce((accumulator, currentValue) => { return parseFloat(accumulator) + parseFloat(currentValue); } , 0);
                let serviceValueList = [...this.state.serviceList]
                serviceValueList.map(item => {
                    if(item.name === service.name)
                        item.sum = Math.round((sum + Number.EPSILON) * 100) / 100
                    return item;
                })
                //serviceValueList.sort(this.compare);
               serviceValueList.sort(function(a, b){
                return a.sum < b.sum ? 1 : -1
            })
                //this.setState({ restaurants: newRestaurants });
                this.setState({ calculatedServiceList: serviceValueList
                }, () => {
                    
                    this.setState({topFiveServiceList : serviceValueList.slice(0,5)}, () => 
                    {
                      //console.log('topFiveServiceList : ' + JSON.stringify(this.state.topFiveServiceList))
                    let data = [];
                    let labelsList = [];
                    let isPieChartDisplay = false;
                    let serviceChartLabelList =[];
                    this.state.topFiveServiceList.forEach(item => {
                      if(item.sum > 0)
                        isPieChartDisplay = true;
                        data.push(item.sum);
                        labelsList.push(item.name);
                        serviceChartLabelList.push(item.name.split(' '));

                    })

                  

                    this.setState({pieChartSeries : data, isPieChartDisplay : isPieChartDisplay},() => {
                      this.setState({serviceBarChartSeries:[]}, () => {
                    
                    let array = [{
                      data : data
                    }]

                    this.setState({serviceBarChartSeries : [...array]});
                  });
                    this.updateServiceBarChartCategories(serviceChartLabelList);
                    });

                    const newObj = { ...this.state.pieChartOptions, labels: labelsList } // create a new object by spreading in the this.state.car and overriding features with our new array 
                    this.setState({ pieChartOptions: newObj }, () => {
                      //console.log('barChartOptions : ' + JSON.stringify(this.state.pieChartOptions))
                    })

                    
                });
                    
                });
         })

        });
         
        }

    handleCallback = (start, end, label) => {
    console.log(start, end, label);
    let startDate = start.toDate();
    let endDate = end.toDate();
   
    this.setState({startDate : startDate, endDate : endDate});
    // console.log("startDate " , moment(startDate).format())
    // console.log("endDate " , moment(endDate).format())
    // if(startMonth === endMonth)
    //   this.getCostAndUsageDailyData(new Date(startDate.getFullYear(), startDate.getMonth() - 1, 2).toISOString().substring(0, 10), end.toISOString().substring(0, 10));
    // else 
      this.getCostAndUsageDailyData(moment(startDate).format().substring(0, 10), moment(endDate).format().substring(0, 10));
      //this.processDateRange(start.toDate(), end.toDate());

      //this.getCostAndUsageDailyMonthlyData();
  }

  dateToYM(date) {
    var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return ''  + m + '-' + y;
}

updateCategories(list){
    const newObj = { ...this.state.barChartOptions, xaxis: {categories : list}  } // create a new object by spreading in the this.state.car and overriding features with our new array 
    this.setState({ barChartOptions: newObj }) // set this.state.car to our new object
    }

updateServiceBarChartCategories(list){
      const newObj = { ...this.state.serviceBarChartOptions, xaxis: {categories : list}  } // create a new object by spreading in the this.state.car and overriding features with our new array 
      this.setState({ serviceBarChartOptions: newObj }) // set this.state.car to our new object
      }

updateMonthlyCostBarChartCategories(list){
  const newObj = { ...this.state.monthlyCostBarChartOptions, xaxis: {categories : list}  } // create a new object by spreading in the this.state.car and overriding features with our new array 
  this.setState({ monthlyCostBarChartOptions: newObj }) // set this.state.car to our new object
  
}
  
updateReverseChartCategories(list){
        const newObj = { ...this.state.reverseBaarChartOptions, xaxis: {categories : list}  } // create a new object by spreading in the this.state.car and overriding features with our new array 
        this.setState({ reverseBaarChartOptions: newObj }) // set this.state.car to our new object
        }

  processDateRange = () =>{
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let startDateMonth = startDate.getMonth() + 1;
    let endDtaeMonth = endDate.getMonth() + 1;

    if(startDateMonth === endDtaeMonth){
        this.setState({perviousMonthStartDate : new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1), 
                       perviousMonthEndDate : new Date(startDate.getFullYear(), startDate.getMonth() , 0),
                       currentMonthStartDate:  startDate,
                       currentMonthEndDate :  endDate,
                       perviousMonthLabel : this.dateToYM(new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)),
                       currentMonthLabel : this.dateToYM(startDate)
                       }, () => this.processDateSelectionRange());
                       //this.updateCategories([this.dateToYM(new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)), this.dateToYM(startDate)]);               
    }
    else {
        this.setState({perviousMonthStartDate : startDate, 
                       perviousMonthEndDate : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0),
                       currentMonthStartDate:  new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1),
                       currentMonthEndDate : endDate,
                       perviousMonthLabel : this.dateToYM(startDate),
                       currentMonthLabel : this.dateToYM(endDate)
                        }, () => this.processDateSelectionRange());
                      //  this.updateCategories([this.dateToYM(startDate), this.dateToYM(endDate)]);
    }

    
    

  }

  processDateSelectionRange(){
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let startDateMonth = startDate.getMonth() + 1;
    let endDtaeMonth = endDate.getMonth() + 1;
    let result =[];

    if(startDateMonth === endDtaeMonth){
      result.push({startVal :startDate, endVal : endDate})
    }
    else {
          if(new Date(endDate.getFullYear(), endDate.getMonth()-1, 1).getMonth() === new Date(startDate.getFullYear(), startDate.getMonth(), 1).getMonth()){
      result.push({startVal :startDate, endVal : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)});
      result.push({startVal :new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1), endVal : endDate})
    }
    else{
      // console.log(startDate);
      let index = 1;
      result.push({startVal :new Date(startDate.getFullYear(), startDate.getMonth() , startDate.getDate()+0), endVal : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)});
      result.push({startVal :new Date(this.state.endDate.getFullYear(), this.state.endDate.getMonth(), 1), endVal : this.state.endDate})
      let mstartDate = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + index,  1);
      //let mendDate = new Date(endDate.getFullYear(), endDate.getMonth() -1, 0)

      while (new Date(mstartDate.getFullYear(), mstartDate.getMonth() + index, 1) < this.state.endDate) {
        result.push({startVal: new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + index,  1), endVal : new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + index +1,  0)});
        //mstartDate.setMonth(mstartDate.getMonth() + 1);
        index++;
      }
    }
  }

    this.setState({selectedDateRange : result.sort((a,b) => {return a.startVal > b.startVal ? 1 : -1})}, () => {this.processDataSet(this.state.statList)})
  }

  onAccountChange = (event) =>{
      this.setState({accountId : event.target.value }, ()=> {this.filterData(); this.getCostAndUsageDailyMonthlyData();})
  }

  onConnectorChange = (event) =>{
    this.setState({connectorId : event.target.value }, ()=> {this.filterData(); this.getCostAndUsageDailyMonthlyData(); this.getForcastData()})
}

   onCostTypeChange = (event) =>{
      this.setState({costType : event.target.value },  ()=> {this.filterData(); this.getCostAndUsageDailyMonthlyData(); this.getForcastData();})
  }
    render(){
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 6);
        return (
           <div>
               <div>
               <Row>
                   <Col span={4}>
                        <Row>
                            <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>                              
                                <select className='select-dash' defaultValue='AWS' disabled style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    
                                    <option value="AWS">AWS</option>
                                </select>
                              
                            </div>
                        </Row>
                    </Col>
                     <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.connectorId} onChange={this.onConnectorChange} style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.connectorList.map((account, i) => {
                                         return (
                                         <option key={`${account}--${i}`} value={account.id}>{account.name}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.accountId}  onChange={this.onAccountChange} style={{width:'100%', backgroundColor: '#ffffff',border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.accountList.map((account, i) => {
                                         return (
                                         <option key={`${account}++${i}`} value={account.id}>{account.id === '-1' ? 'Account ID' : account.id}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                     <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.costType}  onChange={this.onCostTypeChange} style={{width:'100%', backgroundColor: '#ffffff',border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.costTypeList.map((type, i) => {
                                         return (
                                         <option key={`${type}-${i}`} value={type}>{type}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                     <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff'}}>
                                <DateRangePicker onCallback={this.handleCallback}
                                    initialSettings={{ startDate: fromDate, endDate: new Date() }}
                                    >
                                    <input type="text" style={{width:'100%', fontSize:'16px', backgroundColor: '#ffffff', color: '#000000', padding : '5px', borderColor:'#ffffff'}} />
                                    </DateRangePicker>
                            </div>
                        </Row>
                    </Col>
               </Row>
               </div>
               <br/>
               <br/>
              
               <div>
                <div>
                    <Row>
                        <Col span={4}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                        <Row>
                                    <span style={{fontSize:15, fontWeight:'bold'}}>Last Month Cost</span>
                                </Row>
                                <Row>
                                    <span style={{fontSize:20, fontWeight:'bold', color : '#ff3342'}}>$ {Number(this.state.lastMonthTotalCost).toFixed(2)}</span>
                                </Row>
                            </Card>
                        </Col>
                        <Col style={{width:'5px'}}></Col>
                        <Col span={6}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                        <Row>
                                    <span style={{fontSize:15, fontWeight:'bold'}}> Cost Difference From Last Month</span>
                                </Row>
                                <Row>
                                    <span style={{fontSize:20, fontWeight:'bold', color : '#3386ff'}}>$ {Number(this.state.currentMonthTotalCost - this.state.lastMonthTotalCost).toFixed(2)}</span>
                                </Row>
                                </Card>
                        </Col>
                        <Col style={{width:'5px'}}></Col>
                        <Col span={4}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                        <Row>
                                    <span style={{fontSize:15, fontWeight:'bold'}}> Forecasted Cost: Next Month</span>
                                </Row>
                                <Row>
                                    <span style={{fontSize:20, fontWeight:'bold', color : '#3386ff'}}>$ {this.state.monthlyForcast}</span>
                                </Row>
                                </Card>
                        </Col>
                        <Col style={{width:'5px'}}></Col>
                        <Col span={4}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                        <Row>
                                    <span style={{fontSize:15, fontWeight:'bold'}}> Forecasted Cost: 3 Months</span>
                                </Row>
                                <Row>
                                    <span style={{fontSize:20, fontWeight:'bold', color : '#3386ff'}}>$ {this.state.threeMonthsForcast}</span>
                                </Row>
                                </Card>
                        </Col>
                        <Col style={{width:'5px'}}></Col>
                        <Col span={5}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                        <Row>
                                    <span style={{fontSize:15, fontWeight:'bold'}}> Forecasted Cost: 1 Year</span>
                                </Row>
                                <Row>
                                    <span style={{fontSize:20, fontWeight:'bold', color : '#3386ff'}}>$ {this.state.twelMonthsForcast}</span>
                                </Row>
                                </Card>
                        </Col>
                       
                    </Row>
                    </div>
                    
                <br/>
                <br/>
                <div>
                  {this.state.isBarChartDisplay || this.state.isPieChartDisplay ? 
                    <Row > 
                      {this.state.isBarChartDisplay ? 
                        <Col style={{width:'49.75%'}} > 
                        <Card style={{ width: '100%', borderRadius: 8 }}>
                           <div >
                            <ReactApexCharts options={this.state.barChartOptions} series={this.state.barChartSeries} type="bar" height={400} />
                            </div>
                            </Card>
                        </Col>
                        : 
                        <Col style={{width:'49.75%'}} > 
                         <Card style={{ width: '100%', borderRadius: 8 }}>
                           <div style={{padding:'200px 100px', textAlign : 'center'}}>
                        <span>No data available</span>
                        </div>
                        </Card>
                        </Col> }
                        {/* <Col style={{width:'114px'}}></Col> */}
                        <Col style={{background:'#f8f7fc', width:'0.5%'}}></Col>
                     
                         {this.state.isPieChartDisplay ?
                        <Col style={{width:'49.75%'}}>
                          <Card style={{ width: '100%', borderRadius: 8 }}>
                            <div >
                            <ReactApexCharts options={this.state.pieChartOptions} series={this.state.pieChartSeries} type="donut" width={464} height={447} />
                            </div>
                            </Card>
                        </Col>
                        :<Col style={{width:'49.75%'}} > 
                        <Card style={{ width: '100%', borderRadius: 8 }}>
                          <div style={{padding:'200px 100px', textAlign : 'center'}}>
                        <span>No data available</span>
                        </div>
                        </Card>
                        </Col> }
                    </Row>
                    : null}
                    
                </div>
                <br/>
                <br/>
                <div>
                <Row style={{background:'#f8f7fc', height:'7px'}}></Row>
                </div>
                <br/>
                <br/>
                <div>
                  <Row>
                  {this.state.isBarChartDisplay ? 
                        <Col style={{width:'49.75%'}} > 
                        <Card style={{ width: '100%',borderRadius: 8 }}>
                          <div>
                            <ReactApexCharts options={this.state.serviceBarChartOptions} series={this.state.serviceBarChartSeries} type="bar" height={400} />
                          </div>
                           </Card> 
                        </Col>
                        : 
                        <Col style={{width:'49.75%'}} > 
                        <Card style={{ width: '100%' , borderRadius: 8 }}>
                          <div style={{padding:'200px 100px', textAlign : 'center'}}>
                        <span>No data available</span>
                        </div>
                        </Card>
                        </Col> }
                        
                        {/* <Col style={{width:'114px'}}></Col> */}
                        <Col style={{background:'#f8f7fc', width:'0.5%'}}></Col>
                       
                        {this.state.isMonthlyChartDisplay ?
                         <Col style={{width:'49.75%'}} > 
                         <Card style={{ width: '100%' , borderRadius: 8 }}>
                           <div >
                           <ReactApexCharts options={this.state.monthlyCostBarChartOptions} series={this.state.monthlyCostBarChartSeries} type="bar" height={400} />

                           </div>
                        </Card>                      
                        </Col>
                        : <Col style={{width:'49.75%'}} > 
                        <Card style={{ width: '100%', borderRadius: 8 }}>
                          <div style={{padding:'200px 100px', textAlign : 'center'}}>
                        <span>No data available</span>
                        </div>
                        </Card>
                      </Col> }
                        
                  </Row>
                </div>
                <br/>
                <br/>

                <div>
                  <Row>
                    {this.state.calculatedServiceList.length > 0 ? 
                    <Col span={12}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                    <Table columns={this.columns} pagination= { { pageSize :5}} dataSource={this.state.calculatedServiceList} />
                    </Card>
                    </Col>
                     :
                     <Col span={12}>
                         <Card style={{ width: '100%' }}>
                       <div style={{padding:'200px 100px', textAlign : 'center'}}>
                        <span>No data available</span>
                        </div>
                        </Card>
                    </Col>
                      }

                  </Row>
                </div>
                {/* <br/>
                <br/>
                <div>
                  <Row>
                    <Col span={24}>
                      <UserManagement/>
                    </Col>
                  </Row>
                </div> */}
               </div>
           
           </div>

        )
    }

}