import axios from 'axios';

class AWSCloudAdvisor{
    static async getCloudAdvisor(connector)
    {
        
        return await axios.post(`https://melmtzzl33.execute-api.eu-west-1.amazonaws.com/Prod/cloudadvisorsummary` ,{
            "useremail": localStorage.getItem('email'),
            connector
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }


}

export default AWSCloudAdvisor;