import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import aWSConectorAPIHelper from '../../../API/awsConectorAPIHelper';
import {Auth} from "aws-amplify";
import { Col, Row, Card} from "antd";
import {EditOutlined, DeleteOutlined} from "@ant-design/icons";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default class AWSConnector extends Component {
    constructor(props) {
      super(props);
      
       this.state = {
           connectorList : [],
           connectorRoleArnList : [],
           loading: false,
       }
  
    //   this.connectorTableOptions = {
    //   defaultSortName: 'id',  // default sort column name 
    //   defaultSortOrder: 'acs',  // default sort order
    //   noDataText: 'No data available in the table',
    //   page: 1,  // which page you want to show as default
    //   sizePerPageList: [{
    //     text: '10', value: 10
    //   }, {
    //     text: '20', value: 20
    //   }, {
    //     text: 'All', value: this.state.connectorList.length
    //   }], // you can change the dropdown list for size per page
    //   sizePerPage: 10,  // which size per page you want to locate as default
    //   pageStartIndex: 1, // where to start counting the pages
    //   paginationSize: 10,  // the pagination bar size.
    //   prePage: 'Prev', // Previous page button text
    //   nextPage: 'Next', // Next page button text
    //   firstPage: 'First', // First page button text
    //   lastPage: 'Last', // Last page button text
    //   paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    //   paginationPosition: 'bottom',  // default is bottom, top and both is all available
    //   hideSizePerPage: true,  //You can hide the dropdown for sizePerPage
    // };

    this.connectorRoleArnTableOptions = {
      defaultSortName: 'id',  // default sort column name 
      defaultSortOrder: 'acs',  // default sort order
      noDataText: 'No data available in the table',
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }, {
        text: 'All', value: this.state.connectorRoleArnList.length
      }], // you can change the dropdown list for size per page
      sizePerPage: 10,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 10,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      hideSizePerPage: true,  //You can hide the dropdown for sizePerPage
    };

    }

    componentDidMount(){

       Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
             //   logger.log("User is logged-in");
                this.props.userHasAuthenticated(true);
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );
       //this.loadConnectors(); 
       this.loadConnectorRoleArn();
    }

    loadConnectors = () =>{
      this.props.setLoadingWheel(true);
      aWSConectorAPIHelper.get().then(res => {
        if(res.status === 200){ 
          let connectorList = [];    
          if(res.data.body.length > 0){
            let id = 1;
            res.data.body.forEach(element => {
              let item = {
                id : id,
                provider : element[0].stringValue,
                status : element[4].stringValue,
                name : element[1].stringValue,
                accountId : element[3].stringValue
              }
              
              connectorList.push(item);
              id++;
            });

            
          }
        this.setState({connectorList : connectorList});
        this.props.setLoadingWheel(false);
        }
        else{
          this.props.setLoadingWheel(false);
        }
      }).catch(() => {
        this.props.setLoadingWheel(false);
      })
    }

    loadConnectorRoleArn = () =>{
      this.props.setLoadingWheel(true);
      aWSConectorAPIHelper.getRoleArnList().then(res => {
        if(res.status === 200){ 
          let connectorRoleArnList = [];    
          if(res.data.body.length > 0){
            let id = 1;
            res.data.body.forEach(element => {
              let item = {
                id : id,
                provider : element[0].stringValue,
                status : element[4].stringValue,
                name : element[1].stringValue,
                accountId : element[3].stringValue
              }
              
              connectorRoleArnList.push(item);
              id++;
            });

            
          }
        this.setState({connectorRoleArnList : connectorRoleArnList});
        this.props.setLoadingWheel(false);
        }
        else{
          this.props.setLoadingWheel(false);
        }
      }).catch(() => {
        this.props.setLoadingWheel(false);
      })
    }

    addConnector =() => {
      this.props.history.push('/add-connector');
    }

    addConnectorArn =() => {
      this.props.history.push('/add-connector-role-arn');
    }

    deleteConnector = (name) =>{
      // console.log(name)
      this.props.setLoadingWheel(true);
      aWSConectorAPIHelper.delete(name)
      .then(response => {
        if(response.status === 200){
          this.loadConnectors();
        }
        else{
          this.props.setLoadingWheel(false);
        }
      }).catch(err => {
        this.props.setLoadingWheel(false);
      })
    } 
    
    cancel = () => {

    }

    submit = (name) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h2>Delete</h2>
              <p>Are you sure you want to delete connector?</p>
              <button onClick={onClose}>No</button>
              <button
                onClick={() => {
                  this.deleteConnector(name)
                  onClose();
                }}
              >
                Yes
              </button>
            </div>
          );
        }
      });
    };

    OnClickActions(cell, row) {
    return (
      <div>
        <Row>
        <Col span={6}>
          <EditOutlined/>
        </Col>
        <Col span={6} >
      
    <DeleteOutlined onClick={() => this.submit(row.name)}/>
  
        
      
        </Col>
        <Col span={6}>
          <span style={{color:"green"}}>enable</span>
        </Col>
        </Row>
      </div>
    );
  }

  OnClickRoleArnActions(cell, row) {
    return (
      <div>
        <Row>
        <Col span={6}>
          <EditOutlined/>
        </Col>
        <Col span={6} >
      
    <DeleteOutlined onClick={() => this.deleteRoleArn(row.name)}/>
  
        
      
        </Col>
        <Col span={6}>
          <span style={{color:"green"}}>enable</span>
        </Col>
        </Row>
      </div>
    );
  }

  deleteRoleArn = (name) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h2>Delete</h2>
            <p>Are you sure you want to delete connector?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.deleteConnectorRoleArn(name)
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
  };

  deleteConnectorRoleArn = (name) =>{
    // console.log(name)
    this.props.setLoadingWheel(true);
    aWSConectorAPIHelper.deleteRoleArn(name)
    .then(response => {
      if(response.status === 200){
        this.loadConnectorRoleArn();
      }
      else{
        this.props.setLoadingWheel(false);
      }
    }).catch(err => {
      this.props.setLoadingWheel(false);
    })
  } 
  
  cancel = () => {

  }


  accountIDformatter(cell, row) {
    return (
      <div><span style={{fontWeight:'bold'}}>aws_ccount_id:</span>{row.accountId}</div>
    )
  }

    render(){
        return (
          <div>
          {/* <Row>
            
            <Card style={{ width: '100%' , borderRadius: 8}}>
            <Row>
                <span onClick={this.addConnector} className='btn btn-primary' style={{ float: 'left'}}>Add Connector Credentials</span>    
            </Row>
            <br/>
            <Row>               
            
            <BootstrapTable data={this.state.connectorList} options={this.connectorTableOptions} 
                  pagination={true} search={false} width="150" striped hover condensed
                  scrollTop={'Bottom'}>
                  <TableHeaderColumn dataField='id' headerAlign='center' 
                  tdStyle={{ whiteSpace: 'normal' }} dataAlign='center' 
                  thStyle={{ whiteSpace: 'normal' }} dataSort width="50">#</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='name' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="200"
                    dataSort  dataAlign='center' >Connector Name</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center'
                    isKey dataField='provider' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center'>Provider Name</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center'  dataFormat={this.accountIDformatter}  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="200"
                    dataSort>Account ID</TableHeaderColumn>   
                  <TableHeaderColumn headerAlign='center' dataField='status'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="50"
                    dataSort>Status</TableHeaderColumn>             
                  <TableHeaderColumn
                    headerAlign='center'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }}
                    width="100"
                    dataFormat={this.OnClickActions.bind(this)}
                    >Actions</TableHeaderColumn>
                </BootstrapTable>
                </Row>
                <br/>
                <br/>
                </Card>
                
          </Row>
          <Row style={{height:'20px'}}></Row> */}
          <Row>
            
            <Card style={{ width: '100%' , borderRadius: 8}}>
            <Row>
                <span onClick={this.addConnectorArn} className='btn btn-primary' style={{ float: 'left'}}>Add connector RoleARN</span>    
            </Row>
            <br/>
            <Row>               
            
            <BootstrapTable data={this.state.connectorRoleArnList} options={this.connectorRoleArnTableOptions} 
                  pagination={true} search={false} width="150" striped hover condensed
                  scrollTop={'Bottom'}>
                  <TableHeaderColumn dataField='id' headerAlign='center' 
                  tdStyle={{ whiteSpace: 'normal' }} dataAlign='center' 
                  thStyle={{ whiteSpace: 'normal' }} dataSort width="50">#</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='name' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="200"
                    dataSort  dataAlign='center' >Connector Name</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center'
                    isKey dataField='provider' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center'>Provider Name</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center'  dataFormat={this.accountIDformatter}  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="200"
                    dataSort>Account ID</TableHeaderColumn>   
                  <TableHeaderColumn headerAlign='center' dataField='status'  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="50"
                    dataSort>Status</TableHeaderColumn>             
                  <TableHeaderColumn
                    headerAlign='center'
                    dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }}
                    width="100"
                    dataFormat={this.OnClickRoleArnActions.bind(this)}
                    >Actions</TableHeaderColumn>
                </BootstrapTable>
                </Row>
                <br/>
                <br/>
                </Card>
                
          </Row>
          </div>
        
          
        )
    }
}