const awsConfig = {
    aws_app_analytics: 'enable',

    aws_user_pools: 'enable',
    aws_user_pools_id: 'eu-west-1_8xejV92bJ',
    aws_user_pools_mfa_type: 'OFF',
    aws_user_pools_web_client_id: '1lhl6sooi197iheajcihjd8j1s',
    aws_user_settings: 'enable',
    aws_api_gateway: 'https://sc7b4my7e7.execute-api.eu-west-1.amazonaws.com/Stage/'
};

export default awsConfig
