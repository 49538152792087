import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Auth} from "aws-amplify";
import {Logger} from "@aws-amplify/core";


export function Notifications (props) {
    const logger = new Logger("Notifications");

    const history = useHistory();

        useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                history.push("/")
            else{
                logger.log("User is logged-in");
                props.userHasAuthenticated(true);
            }
        }).catch(err => {
                logger.log("Couldn't get the logged-in user for some reason: " + err);
                 props.userHasAuthenticated(false);
                history.push("/")
            }
        );
    })
    return <div><p>Notifications</p></div>
}