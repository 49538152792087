import React, { Component } from "react";
import {Auth} from "aws-amplify";
import { Col, Row} from 'antd';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Button } from 'antd';
import AWSConectorAPIHelper from '../../../API/awsConectorAPIHelper';
import ChatIcon from "../../../assets/images/chat.svg";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import "./style.css"

export default class Summary extends Component {
     constructor(props) {
      super(props);
      
       this.state = {
            statList : [],
            accountList: [],
            connectorList:[],
            AWS_REGIONS : [],
            regions: ["eu-north-1" , "ap-south-1" , "eu-west-3" , "eu-west-2" , "eu-west-1" , "ap-northeast-3" , "ap-northeast-2" , "ap-northeast-1" , "sa-east-1" , "ca-central-1" , "ap-southeast-1" , "ap-southeast-2" , "eu-central-1" , "us-east-1" , "us-east-2" , "us-west-1" , "us-west-2"],
            regionsData: {},
            forcastList:[],
            costTypeList: ['Cost Type'],
            connectorId : '',
            mode: 'top',
            summaryTable : []

       }
       this.summaryTableOptions = {
        defaultSortName: 'id',  // default sort column name 
        defaultSortOrder: 'acs',  // default sort order
        noDataText: 'No data available in the table',
        page: 1,  // which page you want to show as default
        sizePerPageList: [{
          text: '10', value: 10
        }, {
          text: '20', value: 20
        }, {
          text: 'All', value: this.state.summaryTable.length
        }], // you can change the dropdown list for size per page
        sizePerPage: 10,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 10,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        hideSizePerPage: true,  //You can hide the dropdown for sizePerPage
      };
    }


    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
               console.log("User is logged-in");
               this.props.userHasAuthenticated(true);
               this.getConntectors();
                // this.credentialsVerify()
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );

    }

    getConntectors = async () => {
      this.props.setLoadingWheel(true);
      const connectors = await AWSConectorAPIHelper.getRoleArnList();
      this.setState({connectorList : connectors.data.body.map(x => x[1].stringValue)})
      this.props.setLoadingWheel(false);
    }

    onConnectorChange = (event) =>{
        this.setState({connectorId : event.target.value })
    }

    getData = () => {
        if(!this.state.connectorId){
            alert("Please select connector");
            return;
        }
        this.props.setLoadingWheel(true);
        AWSConectorAPIHelper.getSummary(this.state.connectorId)
            .then((res) => {
                this.setState({summaryTable : res.data.body})
                this.props.setLoadingWheel(false);

            })
            .catch(() => {
              this.props.setLoadingWheel(false);
            })
    }
    accountIDformatter(cell, row , index) {
        return (
          <p>
            {/* <b> */}
              { index === 5 ? row[index]?.substring(7) : row[index]?.split(" ")[1]}
            {/* </b> */}
          </p>
        )
      }
    render(){
        return (
           <div>
               <div>
               <div className=" SATopChat">
                    <img src={ChatIcon} alt="chat" className="SAChatIcon" />
                    <span className="my-0 SAChatPara">
                        Summary Idle AWS resources (Idle EC2, RDS, EBS, EIP, ELB)
                    </span>
                </div>
               <Row>
                   <Col span={4}>
                        <Row>
                            <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>                              
                                <select className='select-dash' defaultValue='AWS' disabled style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    
                                    <option value="AWS">AWS</option>
                                </select>
                              
                            </div>
                        </Row>
                    </Col>
                     <Col span={8}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.connectorId} onChange={this.onConnectorChange} style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    <option key={"select Connector Name"} value={"select Connector Name"}>Select Connector Name</option>
                                    
                                    {this.state.connectorList.map(account => {
                                         return (
                                         <option key={account} value={account}>{account}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={this.getData} shape="round" size={'large'}>
                            AWS IDLE Scan
                      </Button>
                    </Col>
               </Row>
               </div>
               <br/>
               <br/>
               <div>
               <BootstrapTable data={this.state.summaryTable} options={this.summaryTableOptions} 
                  pagination={true} search={false} width="150" striped hover condensed
                  scrollTop={'Bottom'}>
                  <TableHeaderColumn dataField='id'
                  dataFormat={(cell , row) => this.accountIDformatter(cell , row , 0)}
                  headerAlign='center' 
                  tdStyle={{ whiteSpace: 'normal' }} dataAlign='center' 
                  thStyle={{ whiteSpace: 'normal' }} dataSort width="50">Region</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center' dataField='name' dataFormat={(cell , row) => this.accountIDformatter(cell , row , 1)} tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center' >Service</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center'
                    dataFormat={(cell , row) => this.accountIDformatter(cell , row , 2)}
                    isKey dataField='provider' tdStyle={{ whiteSpace: 'normal' }}
                    thStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort  dataAlign='center'>Service Name</TableHeaderColumn>
                  <TableHeaderColumn headerAlign='center'  dataFormat={(cell , row) => this.accountIDformatter(cell , row , 3)}  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>Service ID</TableHeaderColumn>   
                  <TableHeaderColumn headerAlign='center' dataField='status' dataFormat={(cell , row) => this.accountIDformatter(cell , row , 4)}  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>Status</TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' dataField='status' dataFormat={(cell , row) => this.accountIDformatter(cell , row , 5)}  dataAlign='center'
                    tdStyle={{ whiteSpace: 'normal' }} width="100"
                    dataSort>Reason</TableHeaderColumn>             
            </BootstrapTable>
      </div>
           </div>

        )
    }

}