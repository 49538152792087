import axios from 'axios';

class AWSRDSSchedularAPIHelper{
    static async get()
    {
        // let url = config.aws_api_gateway;
        
        return await axios.get(`https://6dj2rzg24b.execute-api.eu-west-1.amazonaws.com/Prod/rdsschedulerstate?useremail=${localStorage.getItem('email')}` )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async create(data)
    {
         let url = 'https://knqmrx60cf.execute-api.eu-west-1.amazonaws.com/Prod/rdsscheduler';
         return await axios.post(url, data)
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }
    static async delete(data)
    {
         let url = 'https://2ppjvslkw9.execute-api.eu-west-1.amazonaws.com/Prod/rdsdeleterule';
         let requestData = {
             action : data.action,
             connector : data.connector,
             region : data.region,
             instance_id : data.instance_id,
             useremail : localStorage.getItem('email')
         }
         return await axios.post(url, requestData )
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })

    }
}

export default AWSRDSSchedularAPIHelper;