import React, {useEffect, useState} from 'react';
import {Avatar, Menu, notification} from "antd";
import {Link, useHistory} from "react-router-dom";
import {Hub, Logger} from "@aws-amplify/core";
import {AuthService} from "../../services/auth-service";
import {Auth} from "aws-amplify";
import { LoginOutlined, LogoutOutlined} from "@ant-design/icons";

export function MyMenuTopMain(props) {

    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState("");

    const logger = new Logger('AuthService');
    const history = useHistory();

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            if (user){
                setLoggedIn(true);
                setUsername(user.username);
            }
            else
                setLoggedIn(false);
        }).catch(err => {
            setLoggedIn(false);
        });

        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };
    })

    const onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        if (channel === AuthService.CHANNEL &&
            payload.event === AuthService.AUTH_EVENTS.LOGIN) {
            if (payload.success) {
                setLoggedIn(true);
                setUsername(payload.username);

            }
        } else if (channel === AuthService.CHANNEL &&
            payload.event === AuthService.AUTH_EVENTS.SIGN_OUT) {
            if (payload.success) {
                setLoggedIn(false);
                setUsername("");

                notification.open({
                    type: 'info',
                    message: 'You have logged out',
                    duration: 10
                });

                props.userHasAuthenticated(false);
            }
        }
    };


    const logout = async () => {
        await AuthService.signOut();
        localStorage.clear();
        history.push("/");
    }

    return <Menu
        theme="light"
        mode="horizontal"
        // defaultSelectedKeys={[props.location.pathname]}
        style={{lineHeight: '64px', float: 'right'}}
    >
       
        {/*<Menu.Item key="browse"><Link to="browse"><Icon type="search"/> Browse </Link></Menu.Item>*/}
        <Menu.Item key="addrss" className="ant-menu-item-only-child">
            <Link to="/notifications"><span className="iconify" data-icon="ant-design:bell-outlined" data-inline="false"></span></Link>
        </Menu.Item>
        <Menu.SubMenu
            title={
                <Avatar className="user-top-menu" shape="square" size="large">{username.substring(0,1).toUpperCase()}</Avatar>
            }
        >
            {/* <Menu.ItemGroup title="Settings">
                <Menu.Item key="setting:1"><Link to="settings"><span className="submenu-item-name">Profile</span></Link></Menu.Item>
                <Menu.Item key="setting:2"><Link to="settings"><span className="submenu-item-name">Settings</span></Link></Menu.Item>
            </Menu.ItemGroup> */}

            {loggedIn &&
            <Menu.Item key="auth:1" onClick={logout}><LogoutOutlined/><span className="submenu-item-name"> Log Out </span></Menu.Item>}
            {!loggedIn &&
            <Menu.Item key="auth:2"><Link to="login"><LoginOutlined/><span className="submenu-item-name"> Log In </span></Link></Menu.Item>}
        </Menu.SubMenu>

    </Menu>
}
