import React from 'react';
import userAPIHelper from '../../API/userAPIHelper';
import {Button, Table, Modal, Row} from 'antd';
import FeatherIcon from "feather-icons-react";
import { Col } from 'reactstrap';
import tick from '../../images/tick.png';
import deleteIcon from '../../images/delete.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        
         this.state = {
            userList: [],
            isModalVisible: false,
            email:'',
            group:'Admin',
            loading: false
         }
         this.columns = [
            {
              title: 'Email',
              dataIndex: 'Username',
              key: 'Username'
            },
            {
              title: 'Admin',
              dataIndex: 'isAdmin',
              key: 'isAdmin',
              render: (value, row, index) => {
                if(value)
                  return <img alt="" style={{width:'25px', height:'25px'}} src={tick}></img>
              }
            },
            {
                title: 'Status',
                dataIndex: 'Enabled',
                key: 'Enabled',
                  render: (value, row, index) => {
                      if(value)
                          return 'enable';
                      else
                          return 'disable';
              }
            },
            {
              title: 'Delete',
              key: 'isAdmin',
              render: (value, row, index) => {
               
                  return <img onClick={() => this.submit(row.Username)} alt="" style={{width:'25px', height:'25px'}} src={deleteIcon}></img>
              }
            }
        ]
        }

        componentDidMount(){
          this.getAdminUsers();
        }

        getViewUsers = () => {
          let userList = [];
          let accountUsers =[];
          userAPIHelper.getUsers('View').then(result => {
            if(result.status === 200){
              userList = this.state.userList;
              if(result.data.Users.length > 0){
                result.data.Users.map(user => {
                  if(user.Attributes.find(x=> x.Name === 'custom:accountEmail' && x.Value === localStorage.getItem('accountEmail'))){
                    accountUsers.push(user);
                  }
                  
                })
              }

                this.setState({userList : userList.concat(accountUsers)});
            }
            else{
                this.setState({userList : userList});
            }
        })
        }

        getAdminUsers = () => {
          let userList = [];
          let accountUsers =[];
          userAPIHelper.getUsers('Admin').then(result => {
            if(result.status === 200){
              userList = this.state.userList;
              if(result.data.Users.length > 0){
                //result.data.Users.filter(x => x.Attributes['custom:accountEmail'] === localStorage.getItem('email'));
                result.data.Users.map(user => {
                  if(user.Attributes.find(x=> x.Name === 'custom:accountEmail' && x.Value === localStorage.getItem('accountEmail'))){
                    user.isAdmin = true;
                    accountUsers.push(user);
                  }
                  
                })
              }

                this.setState({userList : userList.concat(accountUsers)}, () => this.getViewUsers());
            }
            else{
                this.setState({userList : userList});
            }
        })
        }

        
        showModal = () => {
            this.setState({isModalVisible : true});
          };
        
          handleOk = () => {
            this.setState({loading :true});
            userAPIHelper.create(this.state.group, this.state.email).then(result => {
              if(result.status === 200){
                
                this.setState({userList: []}, () => this.getAdminUsers());
                this.setState({isModalVisible : false, loading : false, email :''});
                this.success();
                
              }
              else{
                  this.setState({loading: false});
              }
          }).catch(err => {
            this.setState({loading: false});
          })           
          };
        
          handleCancel = () => {
            this.setState({isModalVisible : false});
          };

         
    handleFieldChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }

          validateForm = () => {

          }
          onGroupChange = (event) => {
            this.setState({group : event.target.value });
          }

          success = () => {
            Modal.success({
              content: 'New user added and login details sent to email',
            });
          }

          deleteUser = (email) => {
            this.props.setLoadingWheel(true);
            userAPIHelper.deleteUser(email).then(result => {
              if(result.status === 200){
                this.setState({userList : []}, () => this.getAdminUsers());
                
                this.props.setLoadingWheel(false);
              }
              else{
                this.props.setLoadingWheel(false);
              }
          }).catch(err => {
            this.props.setLoadingWheel(false);
          })           
          }

          submit = (email) => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h2>Delete</h2>
                    <p>Are you sure you want to delete this user?</p>
                    <button onClick={onClose}>No</button>
                    <button
                      onClick={() => {
                        this.deleteUser(email  )
                        onClose();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                );
              }
            });
          };

    render() {
        return (
            <div>
                <div>
                    <Button  className="btn-primary" onClick={this.showModal}>
                        Add User
                    </Button>
                </div>
                 <Table columns={this.columns} pagination= { { pageSize :5}} dataSource={this.state.userList} />
                 <Modal title="Add User" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} okText={"Save"}
                 okButtonProps={{ disabled: this.state.loading }}>
                 <Row>
                     <Col>
                     <div className="position-relative">
                              <label for="email">
                                User Email{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div></div>
                     </Col>
                 <Col>
                 <div><input type='text' placeholder='Email' id='email' name='email' onChange={this.handleFieldChange} onBlur={this.validateForm}></input>

</div>
                 </Col>
                              
                </Row>
                <br/>
                <Row>
                    <Col>
                    <div className="position-relative">
                <label for="group">
                                Role
                                <span className="text-danger">*</span>
                              </label>
                              </div>
                    </Col>
               <Col>
               <div style={{width:'100%'}}>
               <select className='provider-select' value= {this.state.group} onChange={this.onGroupChange} style={{width:'100%'}}>
                    <option key='Admin' value='Admin'>Admin</option>    
                    <option key='View' value='View'>View</option>                                   
                 </select>
                </div>
               </Col> 
                
                </Row>
                
                </Modal>
            </div>
        

        )
    }

}