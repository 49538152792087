
import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Button,
    Card,
    CardBody
  } from "reactstrap";
import {notification} from 'antd';
import FeatherIcon from "feather-icons-react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import user01 from "../../assets/images/user/tech-cloud-bg-3.jpg";
import {AuthService} from "../../services/auth-service";
import {Hub, Logger} from '@aws-amplify/core';
import logo from '../../images/logo.png';


export function LoginForm (props) {
    const logger = new Logger('LoginForm');
    const history = useHistory();


    // const [errorMessage, setErrorMessage] = useState("");
    // const [userNotConfirmed, setUserNotConfirmed] = useState(false);

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');

        // Auth.currentAuthenticatedUser({
        //     bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        // }).then(user => {
        //     if (user)
        //         history.push("/home")

        // }).catch(err => console.log(err));

        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };
    });

    const onloginSuccess = () => {
        props.userHasAuthenticated(true);
    }

    const routeToChangePassword = (user) => {
      props.setUserDetails(user);
     
  }


    // Default handler for listening events
    const onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        if (channel === AuthService.CHANNEL && payload.event === AuthService.AUTH_EVENTS.LOGIN) {
            logger.info("Hub Payload: " + JSON.stringify(payload));
            if (!payload.success) {
                logger.info("Payload error: " + JSON.stringify(payload.error));

                //setErrorMessage(payload.message);

                if (payload.error.code === 'UserNotConfirmedException') {
                    notification.open({
                        type: 'error',
                        message: 'Could not log in',
                        description: 'You have not confirmed your email. We have sent you another code. Please use it to confirm your email.',
                        duration: 20
                    });
                   // setUserNotConfirmed(true);

                    // Resending another code
                    AuthService.resendConfirmationCode(payload.email);

                    history.push("/registerconfirm");


                } else {
                    notification.open({
                        type: 'error',
                        message: 'Could not log in',
                        description: payload.message,
                        duration: 10
                    });
                }
            } else {
              let email = payload.username;
              localStorage.setItem('email', email);
              if(payload.user.challengeName && payload.user.challengeName === 'NEW_PASSWORD_REQUIRED'){
               let user = payload.user;
               //localStorage.setItem('user', JSON.stringify(user));
               routeToChangePassword(user);
               history.push('/forcechangepassword')
               
              }
              else{
                if(payload.user.attributes['custom:isTeamMember'] && payload.user.attributes['custom:isTeamMember'] === 'true'){
                  localStorage.setItem('accountEmail', payload.user.attributes['custom:accountEmail']);
                }else {
                  localStorage.setItem('accountEmail', email);
                }
                
                notification.open({
                  type: 'success',
                  message:
                      ' You have successfully logged in!',
                  description: 'Welcome!',
              });
              // console.log(props);
              
              onloginSuccess();
              history.push("/dashboard")
                
              }
                
            }
        }
    };
    const onFinish = (event, values) => {
        // console.log('Success:', values);        
        AuthService.login(values.email, values.password);
    };

  

    return  <React.Fragment>
                <section className="cover-user bg-white" style={{width:'100%'}}>
          <Container fluid className="px-0">
            <Row className="no-gutters position-relative">
              <Col lg={4} xs={{ order: 2 }} className="cover-my-30">
                <div className="cover-user-img d-flex align-items-center">
                  <Row>
                    <Col xs={12}>
                      <Card
                        className="login-page border-0"
                        style={{ zIndex: "1" }}
                      >
                        <CardBody className="p-0">
                        <Row style={{justifyContent: 'center'}}>
                            <img src={logo} style={{width : '150px', height : '150px'}} alt=""></img>
                        </Row>
                          <h4 className="card-title text-center">Login</h4>
                          <AvForm 
                                onValidSubmit={onFinish}
                                className="llogin-form mt-4">
                            <Row>
                              <Col lg={12}>
                                <FormGroup className="position-relative">
                                  <Label htmlFor="email">
                                    Your Email{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <div className="position-relative">
                                  <i>
                                    <FeatherIcon 
                                      icon="user"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                  </div>
                                  <AvField style={{marginLeft:'10px'}}
                                    type="text"
                                    className="form-control pl-5"
                                    name="email"
                                    id="email"
                                    placeholder="Enter Email"
                                    required
                                    errorMessage=""
                                    
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Please enter your email",
                                      },
                                      pattern: {
                                        value:
                                          "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                        errorMessage: "E-Mail is not valid!",
                                      },
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg={12}>
                                <FormGroup className="position-relative">
                                  <Label htmlFor="password">
                                    Password
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <div className="position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="lock"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                  </div>
                                  <AvField style={{marginLeft:'10px'}}
                                    type="password"
                                    className="form-control pl-5"
                                    name="password"
                                    id="password"
                                    placeholder="Enter password"
                                    required
                                    errorMessage=""
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Please enter Password",
                                      },
                                      minLength: {
                                        value: 6,
                                        errorMessage:
                                          "Your password must be between 6 and 8 characters",
                                      },
                                      maxLength: {
                                        value: 16,
                                        errorMessage:
                                          "Your password must be between 6 and 8 characters",
                                      },
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="12">
                                <div className="d-flex justify-content-between">
                                  <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck1"
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="customCheck1"
                                      >
                                        Remember me
                                      </Label>
                                    </div>
                                  </FormGroup>
                                  <p className="float-right forgot-pass">
                                    <Link
                                      to="forgotpassword1"
                                      className="text-dark font-weight-bold"
                                    >
                                      Forgot password ?
                                    </Link>
                                  </p>
                                </div>
                              </Col>

                              <Col lg={12} className="mb-0">
                                <FormGroup>
                                  <Button color="primary" block style={{height:'50px'}}>
                                    Sign in
                                  </Button>
                                </FormGroup>
                               
                              </Col>
                             

                              {/* <Col lg={12} className="mt-4 text-center">
                                <h6>Or Login With</h6>
                                <ul className="list-unstyled social-icon mb-0 mt-3">
                                  <li className="list-inline-item">
                                    <Link to="#" className="rounded mr-1">
                                      <i>
                                        <FeatherIcon
                                          icon="facebook"
                                          className="fea icon-sm fea-social"
                                        />
                                      </i>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#" className="rounded mr-1">
                                      <i>
                                        <FeatherIcon
                                          icon="github"
                                          className="fea icon-sm fea-social"
                                        />
                                      </i>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#" className="rounded mr-1">
                                      <i>
                                        <FeatherIcon
                                          icon="twitter"
                                          className="fea icon-sm fea-social"
                                        />
                                      </i>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#" className="rounded">
                                      <i>
                                        <FeatherIcon
                                          icon="gitlab"
                                          className="fea icon-sm fea-social"
                                        />
                                      </i>
                                    </Link>
                                  </li>
                                </ul>
                              </Col> */}

                              <Col className="text-center">
                                <p className="mb-0 mt-3">
                                  <small className="text-dark mr-2">
                                    Don't have an account ?
                                  </small>{" "}
                                  <Link
                                    to="register"
                                    className="text-dark font-weight-bold"
                                  >
                                    Sign Up
                                  </Link>
                                </p>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                lg={{ size: 8, offset: 4 }}
                xs={{ order: 1 }}
                className="padding-less img "
                style={{ backgroundImage: `url(${user01})` }}
              ></Col>
            </Row>
          </Container>
        </section>
            </React.Fragment>
   
}

