import React, { Component } from "react";
import {Auth} from "aws-amplify";
import {Col, Row, notification} from 'antd';
import aWSConectorAPIHelper from '../../../API/awsConectorAPIHelper';


export default class AddConnectorArn extends Component {
     constructor(props) {
      super(props);
      
       this.state = {           
           loading: false,
           selectedProvider : {label : 'AWS', value : 'AWS'},
           isInComplete: true,
           name:'', 
           externalId:'',
           roleArn:''
       }

       this.options = [{lable : 'AWS', value : 'AWS'}];
        this.style = {
            control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none"
            })
        };
    }

   

    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
             //   logger.log("User is logged-in");
                this.props.userHasAuthenticated(true);
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );

    }

    onSubmit = () =>{
        if(this.validateForm()){
            this.props.setLoadingWheel(true);
        aWSConectorAPIHelper.createRoleArn(this.state.externalId, this.state.roleArn, this.state.name)
        .then(res => {
            if(res.status === 200) {
                if(res.data.errorMessage){
                    if(res.data.errorMessage.includes('(AccessDenied)')){
                        notification.open({
                        type: 'error',
                        message: 'Could not save data',
                        description: 'Invalid external id or invalid role arn.',
                        duration: 20
                        })
                    }
                    else{
                        notification.open({
                            type: 'error',
                            message: 'Could not save data',
                            description: 'Unable to save the details.',
                            duration: 20
                            });
    
                          this.props.setLoadingWheel(false);
                    }

                }
                else{
                   this.props.setLoadingWheel(false);
                    this.props.history.push('/aws-connector');
                }
                
                this.props.setLoadingWheel(false);
            }
            else
            {
                notification.open({
                        type: 'error',
                        message: 'Could not save data',
                        description: 'Unable to save the details.',
                        duration: 20
                        });

                      this.props.setLoadingWheel(false);
            }
        })
        .catch(err => {
            notification.open({
                        type: 'error',
                        message: 'Could not save data',
                        description: 'Unable to save the details, please contact administrator.',
                        duration: 20
                        });

            this.props.setLoadingWheel(false);
        })
    }
    else{
           notification.open({
                        type: 'error',
                        message: 'Could not save data',
                        description: 'Required field is missing.',
                        duration: 20
                        });
    }

    }

    validateForm =() =>{
        let isvalidName, isValidId, isValidRolerArn = false;

        if(this.state.name && this.state.name !== '')
            isvalidName = true;
        
        if(this.state.externalId && this.state.externalId !== '')
            isValidId =true
        
        if(this.state.roleArn && this.state.roleArn !== '')
            isValidRolerArn = true

        if( isvalidName && isValidRolerArn && isValidId)
            this.setState({isInComplete : false});
        else
            this.setState({isInComplete : true});

     return isvalidName && isValidRolerArn && isValidId

    }

    handleFieldChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
}

     render(){
        return (
            
          <div>
               
              <span>Configure Cloud Platform Connector</span>
              <hr/>
              
              <div>
                  <Row>
                     <Col style={{width:'20%'}}>
                          <span style={{fontWeight:'bold'}}>Select Provider</span>
                     </Col> 
                     <Col style={{width:'20%'}}>
                     </Col>
                     <Col style={{width:'60%'}}>
                          <span style={{fontWeight:'bold'}}>Connector Name</span>
                     </Col> 
                  </Row>
                  <Row>
                  <Col style={{width:'20%'}}>
                 
                 <select className='provider-select' defaultValue='AWS'>
                    <option value="AWS">AWS</option>
                </select>
                 
                  </Col>
                   <Col style={{width:'20%'}}>
                     </Col>
                  <Col style={{width:'60%'}}>
                 
                  <input type='text' name='name' id='name' onChange={this.handleFieldChange} onBlur={this.validateForm} style={{borderColor:'#b2beb5', backgroundColor:'#f8f7fc'}}></input>
                  </Col>
                  </Row>
                  <br/>
              </div>
              <hr/>
              <div>
                  <span style={{fontWeight:'bold'}}>Description</span>
                  <br/>
                  <br/>
                  <Row>
                     <span>AWS Connector is used to connect to your AWS account to retrieve data. For each service that need to be enabled for AWS, a specific set of permissions are required. Click here to identify the IAM privileges that are required for each service</span>
                  </Row>
                  <br/>
                 
              </div>
              <hr/>
              <div>
                  <span style={{fontWeight:'bold'}}>AWS Connector Credentials</span>
                  <br/>
                  <br/>
                  <Row>
                        <Col style={{width:'40%'}}>
                 
                  <input type='text' placeholder='AWS External Id*' id='externalId' name='externalId' style={{borderColor:'#b2beb5', backgroundColor:'#f8f7fc'}} onChange={this.handleFieldChange} onBlur={this.validateForm}></input>
                  </Col>
                    <Col style={{width:'60%'}}>
                 
                  <input type='text' placeholder='AWS RoleARN*' id='roleArn' name='roleArn' style={{borderColor:'#b2beb5', backgroundColor:'#f8f7fc'}} onChange={this.handleFieldChange} onBlur={this.validateForm}></input>
                  </Col>
                  </Row>
                  <br/>
              </div>
              <br/>
              <br/>
              <br/>
              <div>
                  <Row>
                  <Col style={{width:'70%'}}>
                      <button className='btn btn-primary' style={{float:'right'}} onClick={()=> this.props.history.push('/aws-connector')} >Cancel</button>
                  </Col>
                  <Col  style={{width:'10%'}}></Col>
                   <Col style={{width:'20%'}}>
                    <button className='btn btn-primary' style={{float:'left'}} disabled={this.state.isInComplete} onClick={this.onSubmit}>Submit</button>
                  </Col>
                  </Row>
              </div>
              <br/>
          </div>
        )
     }
}