import axios from 'axios';

class AWSIDLEAPIHelper{
    static async IdleRDS(connector)
    {
        
        return await axios.post(`https://1h7j15bfz8.execute-api.eu-west-1.amazonaws.com/Stag/idle-rds-instances` ,{
            "useremail": localStorage.getItem('email'),
            connector
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }
    static async IdleEC2(connector)
    {
        
        return await axios.post(`https://mqwtw1e8g4.execute-api.eu-west-1.amazonaws.com/Stag/low-utilization-ec` ,{
            "useremail": localStorage.getItem('email'),
            connector
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }
    static async IdleEIP(connector)
    {
        
        return await axios.post(`https://hel97pzvfd.execute-api.eu-west-1.amazonaws.com/Stag/idle-eip` ,{
            "useremail": localStorage.getItem('email'),
            connector
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }


    static async IdleEBS(connector)
    {
        
        return await axios.post(`https://a8yns8w7i1.execute-api.eu-west-1.amazonaws.com/Stag/underutilized-ebs-volume` ,{
            "useremail": localStorage.getItem('email'),
            connector
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }

    static async IdleELB(connector)
    {
        
        return await axios.post(`https://ir6zhziu9f.execute-api.eu-west-1.amazonaws.com/Stag/idle-elb` ,{
            "useremail": localStorage.getItem('email'),
            connector
        })
            .then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
    }


}

export default AWSIDLEAPIHelper;