import React, { Component } from "react";
import {Auth} from "aws-amplify";
import {Table, Col, Row, Card} from 'antd';
import statisticAPIHelper from '../../API/statisticAPIHelper';
import ReactApexCharts from 'react-apexcharts'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default class Dashboard extends Component {
     constructor(props) {
      super(props);
      
       this.state = {
            statList : [],
            accountList: [],
            costTypeList: [],
            connectorList:[],
            connectorId : '-1',
            accountId : '-1',
            costType : '',
            serviceList : [],
            startDate : new Date(),
            endDate : new Date(),
            serviceBalanceList :[],
            selectedDateRange :[],
            options: {
              colors: [
                "#02E675",
                "#FF9A00",
                "#75E7FF",
                "#002438",
                "#785F9D",
                "#FA6968",
                "#2F4DFF",
                "#17cf73",
                "#7e526d",
                "#b2b514",
                "#99b702",
                "#0dda1f",
                "#82aae9",
                "#c9aaad",
                "#5b3b25",
                "#8cbc8c",
                "#9b92b1",
                "#143e83",
                "#4340af",
                "#fe9a90",
                "#089d3f",
                "#ea61bd",
                "#ec611c",
                "#092f9e"
              ],
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"]
              },
              legend: {
                show: true
              },
              xaxis: {
                categories: []
              },
              yaxis: {
                decimalsInFloat: 0,
                labels: {
                  formatter: function (value) {
                      return '$' + Math.round((value+ Number.EPSILON) * 100)/ 100

                  }
                },
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {}
              },
              chart: {
                stacked: true,
                toolbar: {
                  show: false
                }
              }
            },
            series: []
          };

          this.columns = [
            {
              title: 'Product Name',
              dataIndex: 'name',
              key: 'name'
            },
            {
              title: 'Cost-Month-to-date',
              dataIndex: 'sum',
              key: 'sum',
              render: (value, row, index) => {
                return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            }]
            
       }
    

    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
             //   logger.log("User is logged-in");
                this.props.userHasAuthenticated(true);
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() -7);
        this.setState({startDate : fromDate, endDate : toDate})
        this.getCostAndUsageDailyData(fromDate.toISOString().substring(0, 10), toDate.toISOString().substring(0, 10));


    }

    getCostAndUsageDailyData = (fromDate, toDate) =>{
       
        this.props.setLoadingWheel(true);
        this.setState({statList : []})
        statisticAPIHelper.dailyDataGet(fromDate, toDate)
        .then(res => {
            if(res.status === 200){
                  let statList = [];    
                if(res.data.body.records.length > 0){
                    res.data.body.records.forEach(element => {
                        
                    let item = {
                        accountId : element[0].stringValue,
                        service: element[1].stringValue,
                        cost : element[2].stringValue,
                        start_timeperiod : new Date(element[3].stringValue),
                        end_timeperiod	 : new Date(element[4].stringValue),
                        cost_type : element[5].stringValue,
                        accountname : element[6].stringValue,
                        date : element[7].stringValue,
                        start_month : new Date(element[3].stringValue).getMonth(),
                        end_month : new Date(element[4].stringValue).getMonth(),
                    }
                    
                    statList.push(item);
                    
                })
                    this.setState({statList : statList}, () => { this.processDataSet(statList)})
                    this.props.setLoadingWheel(false);
                }
                else{
                    this.props.setLoadingWheel(false);
                }
            }
             else{
                this.props.setLoadingWheel(false);
            }
        }).catch(() => {
          this.props.setLoadingWheel(false);
        })
    }

     processDataSet = (statList) => {
        let processedAccountList = [];
        let processedServiceList = [];
        let processedConnectorList = [];
        processedAccountList.push({id : '-1', name : 'Connector Name'});
        processedConnectorList.push({id : '-1', name : 'Connector Name'});
        let processedCostType = [];
         processedCostType.push('Cost Type');
        //  processedCostType.push('Unblended');
        statList.forEach(ele => {
          let account =  processedAccountList.filter(acc => acc.id === ele.accountId);
          if(account.length === 0)
            processedAccountList.push({id : ele.accountId, name : ele.accountname});

          let costtype = processedCostType.filter(type => type === ele.cost_type);
          if(costtype.length ===0)
            processedCostType.push(ele.cost_type);

          let connector =  processedConnectorList.filter(acc => acc.id === ele.accountname);
            if(connector.length === 0)
            processedConnectorList.push({id : ele.accountname, name : ele.accountname});

          let service = processedServiceList.filter(service => service.name === ele.service);
          if(service.length ===0)
            processedServiceList.push({name : ele.service, sum : 0.00});
        })

        let isUnblendedCostAvailable = processedCostType.find(item => item === 'UnblendedCost');
        if(isUnblendedCostAvailable)
          this.setState({costType : 'UnblendedCost'});
        else
          this.setState({costType : processedServiceList[0]});

        this.setState({accountList : processedAccountList, connectorList : processedConnectorList, costTypeList : processedCostType, serviceList : processedServiceList}, () => {this.filterData()});
    }

   
    filterData =() => {
      this.setState({barChartSeries : []}, () => {
        let serviceDataList=[];
        let givenRangeStatList = this.state.statList.filter(item => {return item.start_timeperiod >= new Date(this.state.startDate.toISOString().substring(0, 10) )
                                                                            && item.end_timeperiod < new Date(this.state.endDate.toISOString().substring(0, 10) )
                                                                            && (this.state.accountId === '-1' ? true : item.accountId === this.state.accountId) 
                                                                            && (this.state.costType === 'Cost Type' ? true : item.cost_type === this.state.costType)
                                                                            && (this.state.connectorId === '-1' ? true : item.accountname === this.state.connectorId) 
                                                                 });
        
          this.state.serviceList.forEach(service => {
            let data =[];
            for (var d =  new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth(), this.state.startDate.getDate() + 1); d <= this.state.endDate ; d.setDate(d.getDate() + 1)) {
            let sum =0;
            // eslint-disable-next-line
                let serviceList = givenRangeStatList.filter(item => item.service === service.name && item.start_timeperiod.toISOString().substring(0, 10) === d.toISOString().substring(0, 10));
                if(serviceList.length > 0){
                  const values = serviceList.map(obj => obj.cost);
                  sum = values.reduce((accumulator, currentValue) => { return (Math.round((parseFloat(accumulator)+ Number.EPSILON) * 100)/ 100) + parseFloat(currentValue); } , 0);
                }
                else {
                  sum = 0;
                }

                data.push(Math.round((sum+ Number.EPSILON) * 100)/ 100 );
            
            }
            serviceDataList.push({
              name : service.name , data : data
            })
          })
          let categoryList = [];
          for (var d =  new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth(), this.state.startDate.getDate() + 1); d <= this.state.endDate ; d.setDate(d.getDate() + 1)) {
            categoryList.push(d.toISOString().substring(0,10));
          }

          this.updateCategories(categoryList);
          this.setState({series : serviceDataList});


          this.state.serviceList.forEach(service => {
            let serviceList = givenRangeStatList.filter(item => item.service === service.name);
            const values = serviceList.map(obj => obj.cost);
            const sum = values.reduce((accumulator, currentValue) => { return (Math.round((parseFloat(accumulator)+ Number.EPSILON) * 100)/ 100) + parseFloat(currentValue); } , 0);
               let serviceValueList = [...this.state.serviceList]
               serviceValueList.map(item => {
                   if(item.name === service.name)
                       item.sum = Math.round((sum + Number.EPSILON) * 100) / 100
                   return item;
               })
               //serviceValueList.sort(this.compare);
              serviceValueList.sort(function(a, b){
               return a.sum < b.sum ? 1 : -1
           })
               //this.setState({ restaurants: newRestaurants });
               this.setState({ serviceList: serviceValueList});
        })

        
        
       


         
         
        });
      }
        

    handleCallback = (start, end, label) => {
    // console.log(start, end, label);
    let startDate = start.toDate();
    let endDate = end.toDate();
   
    this.setState({startDate : startDate, endDate : endDate});
    this.getCostAndUsageDailyData(startDate.toISOString().substring(0, 10), end.toISOString().substring(0, 10));
    
    
  }


updateCategories(list){
    const newObj = { ...this.state.options, xaxis: {categories : list}  } // create a new object by spreading in the this.state.car and overriding features with our new array 
    this.setState({ options: newObj }) // set this.state.car to our new object
    }

  // processDateRange = () =>{
  //   let startDate = this.state.startDate;
  //   let endDate = this.state.endDate;
  //   let startDateMonth = startDate.getMonth() + 1;
  //   let endDtaeMonth = endDate.getMonth() + 1;

  //   if(startDateMonth === endDtaeMonth){
  //       this.setState({perviousMonthStartDate : new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1), 
  //                      perviousMonthEndDate : new Date(startDate.getFullYear(), startDate.getMonth() , 0),
  //                      currentMonthStartDate:  startDate,
  //                      currentMonthEndDate :  endDate,
  //                      perviousMonthLabel : this.dateToYM(new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)),
  //                      currentMonthLabel : this.dateToYM(startDate)
  //                      }, () => this.processDateSelectionRange());
  //                      //this.updateCategories([this.dateToYM(new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)), this.dateToYM(startDate)]);               
  //   }
  //   else {
  //       this.setState({perviousMonthStartDate : startDate, 
  //                      perviousMonthEndDate : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0),
  //                      currentMonthStartDate:  new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1),
  //                      currentMonthEndDate : endDate,
  //                      perviousMonthLabel : this.dateToYM(startDate),
  //                      currentMonthLabel : this.dateToYM(endDate)
  //                       }, () => this.processDateSelectionRange());
  //                     //  this.updateCategories([this.dateToYM(startDate), this.dateToYM(endDate)]);
  //   }

    
    

  // }

  // processDateSelectionRange(){
  //   let startDate = this.state.startDate;
  //   let endDate = this.state.endDate;
  //   let startDateMonth = startDate.getMonth() + 1;
  //   let endDtaeMonth = endDate.getMonth() + 1;
  //   let result =[];

  //   if(startDateMonth === endDtaeMonth){
  //     result.push({startVal :startDate, endVal : endDate})
  //   }
  //   else {
  //         if(new Date(endDate.getFullYear(), endDate.getMonth()-1, 1).getMonth() === new Date(startDate.getFullYear(), startDate.getMonth(), 1).getMonth()){
  //     result.push({startVal :startDate, endVal : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)});
  //     result.push({startVal :new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1), endVal : endDate})
  //   }
  //   else{
  //     console.log(startDate);
  //     let index = 1;
  //     result.push({startVal :new Date(startDate.getFullYear(), startDate.getMonth() , startDate.getDate()+0), endVal : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)});
  //     result.push({startVal :new Date(this.state.endDate.getFullYear(), this.state.endDate.getMonth(), 1), endVal : this.state.endDate})
  //     let mstartDate = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + index,  1);
  //     //let mendDate = new Date(endDate.getFullYear(), endDate.getMonth() -1, 0)

  //     while (new Date(mstartDate.getFullYear(), mstartDate.getMonth() + index, 1) < this.state.endDate) {
  //       result.push({startVal: new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + index,  1), endVal : new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + index +1,  0)});
  //       //mstartDate.setMonth(mstartDate.getMonth() + 1);
  //       index++;
  //     }
  //   }
  // }

  //   this.setState({selectedDateRange : result.sort((a,b) => {return a.startVal > b.startVal ? 1 : -1})}, () => {this.processDataSet(this.state.statList)})
  // }

  onAccountChange = (event) =>{
      this.setState({accountId : event.target.value }, ()=> {this.filterData();})
  }

  onConnectorChange = (event) =>{
    this.setState({connectorId : event.target.value }, ()=> {this.filterData();})
}

   onCostTypeChange = (event) =>{
      this.setState({costType : event.target.value },  ()=> {this.filterData();})
  }
    render(){
        const fromDate = new Date();
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        fromDate.setDate(fromDate.getDate() - 7);
        return (
           <div>
               <div>
               <Row>
               <Col span={4}>
                        <Row>
                            <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>                              
                                <select className='select-dash' defaultValue='AWS' disabled style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    
                                    <option value="AWS">AWS</option>
                                </select>
                              
                            </div>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.connectorId} onChange={this.onConnectorChange} style={{width:'100%', backgroundColor: '#ffffff', border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.connectorList.map((account, i) => {
                                         return (
                                         <option key={`${account}-${i}`} value={account.id}>{account.name}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.accountId}  onChange={this.onAccountChange} style={{width:'100%', backgroundColor: '#ffffff',border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.accountList.map((account, i) => {
                                         return (
                                         <option key={`${account}+${i}`} value={account.id}>{account.id === '-1' ? 'Account ID' : account.id}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff', borderRadius: 8}}>
                                <select className='select-dash' value= {this.state.costType}  onChange={this.onCostTypeChange} style={{width:'100%', backgroundColor: '#ffffff',border: '0px', color: '#000000', padding : '5px', borderColor:'#ffffff'}}>
                                    {this.state.costTypeList.map(type => {
                                         return (
                                         <option key={type} value={type}>{type}</option>
                                         )
                                    })}
                                   
                                </select>
                            </div>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <Row>
                             <div style={{width:'85%', padding:'5px', backgroundColor: '#ffffff'}}>
                                <DateRangePicker onCallback={this.handleCallback}
                                    initialSettings={{ startDate: fromDate, endDate: endDate }}
                                    >
                                    <input type="text" style={{width:'100%', fontSize:'16px', backgroundColor: '#ffffff', color: '#000000', padding : '5px', borderColor:'#ffffff'}} />
                                    </DateRangePicker>
                            </div>
                        </Row>
                    </Col>
               </Row>
               </div>
               <br/>
               <br/>
               {this.state.statList.length > 0 ? 
               <div>
                <br/>
                <br/>
                <div>
                 
                    <Row>
                      
                        <Col span={24}>
                        <Card style={{ width: '100%' , borderRadius: 8}}>
                            <ReactApexCharts options={this.state.options} series={this.state.series} type="bar" height={350} />
                            </Card>
                        </Col>
                                      
                    </Row>
                                  
                </div>
                <br/>

                <div>
                  <Row>
                    <Col span={12}>
                    <Card style={{ width: '100%' , borderRadius: 8}}>
                    <Table columns={this.columns} pagination= { { pageSize :5}} dataSource={this.state.serviceList} />
                    </Card>
                    </Col>
                  </Row>
                </div>
               </div>
             : null }
           </div>

        )
    }

}