import React, { Component } from "react";
import {Auth} from "aws-amplify";
import { Col, Row, notification , Select , Input , Button} from 'antd';
import awsRDSSchedulerAPIHelper from '../../../API/awsRDSSchedulerAPIHelper';

const { Option } = Select;
export default class AddRDSSchedular extends Component {
     constructor(props) {
      super(props);
      
       this.state = {           
           loading: false,
           selectedProvider : {label : 'AWS', value : 'AWS'},
           isInComplete: true,
           email: "",
           cron_string : "",
           action: "start",
           region: "",
           connector: "",
           instance_id : ""
       }

       this.options = [{lable : 'AWS', value : 'AWS'}];
        this.style = {
            control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none"
            })
        };
    }

   

    componentDidMount(){
         Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            if (!user)
                this.props.history.push("/")
            else{
             //   logger.log("User is logged-in");
                this.props.userHasAuthenticated(true);
                this.setState({email: localStorage.getItem('email')});
            }
        }).catch(err => {
               // logger.log("Couldn't get the logged-in user for some reason: " + err);
                this.props.userHasAuthenticated(false);
                this.props.history.push("/")
            }
        );

    }

    onSubmit = () =>{
        if(this.validateForm()){
            this.props.setLoadingWheel(true);
        awsRDSSchedulerAPIHelper.create(this.getSendObj())
        .then(res => {
            if(res.status === 200 && res.data.body && res.data.body.includes("rule added")) {
                    notification.open({
                        type: 'success',
                        message: 'rule added',
                        description: 'rule added',
                        duration: 20
                    });
                   this.props.setLoadingWheel(false);
                   this.props.history.push('/rds-schedular');                
                this.props.setLoadingWheel(false);
            }else if(res.status === 200 && res.data.errorMessage.includes("already exist")){
                this.props.setLoadingWheel(false);
                notification.open({
                    type: 'error',
                    message: 'rule already exist',
                    description: 'rule already exist',
                    duration: 20
                });
            }
            else
            {
                notification.open({
                        type: 'error',
                        message: 'Could not save data',
                        description: 'Unable to save the details.',
                        duration: 20
                        });

                      this.props.setLoadingWheel(false);
            }
        })
        .catch(err => {
            notification.open({
                        type: 'error',
                        message: 'Could not save data',
                        description: 'Unable to save the details, please contact administrator.',
                        duration: 20
                        });

            this.props.setLoadingWheel(false);
        })
    }
    else{
           notification.open({
                type: 'error',
                message: 'Could not save data',
                description: 'Required field is missing.',
                duration: 20
            });
    }

    }

    getSendObj =() => {
        let obj = {
            "useremail": this.state.email || '',
            "connector": this.state.connector || '',
            "action": this.state.action,
            "instance_id": this.state.instance_id || '',
            //"dbidentifier": "database-1",
            "region": this.state.region || '',
            "cron_string": this.state.cron_string || '',
            // ""
        }
        return obj;
           
    }


    validateForm =() =>{
        let cron_string, region, email, connector, instance_id  = false;

        if(this.state.email && this.state.email !== '')
            email = true
        
        if(this.state.connector && this.state.connector !== '')
            connector =true
        
        if(this.state.instance_id && this.state.instance_id !== '')
            instance_id = true
        
        if(this.state.region && this.state.region !== '')
            region = true
        
        if(this.state.cron_string && this.state.cron_string !== '' && this.state.cron_string.split(" ").length === 6)
            cron_string = true

        if( email && connector && instance_id && cron_string && region)
            this.setState({isInComplete : false});
        else
            this.setState({isInComplete : true});

     return email && connector && instance_id && region && cron_string

    }

    handleFieldChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
}

     render(){
        return (
            
          <div>
               
              <h4>Create RDS Instance Start/Stop Rule</h4>
              <hr/>
              <div>
                  <br/>
                  <Row>
                     <span>
                     An RDS rule watches for certain events and then routes them to AWS targets that you choose. You can create a rule that performs
an AWS action automatically, or a rule that performs an AWS action regularly on a set
schedule
                     </span>
                  </Row>
                  <br/>
                 
              </div>
              <div>
                  <Row>
                     <Col style={{width:'60%'}}>
                          <span style={{fontWeight:'bold'}}>Email</span>
                     </Col> 
                  </Row>
                  <Row>
                    <Col style={{width:'20%'}}>
                        <Input type='text' value={this.state.email} disabled name='email' id='email' onChange={this.handleFieldChange} onBlur={this.validateForm} style={{borderColor:'#b2beb5'}}></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'20%', marginTop : "10px" , marginBottom: "10px"}}>
                        <span style={{fontWeight:'bold'}}>Connector Name</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'60%'}}>
                        <Input type='text' name='connector' id='connector' onChange={this.handleFieldChange} onBlur={this.validateForm} style={{borderColor:'#b2beb5'}}></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'20%', marginTop : "10px" , marginBottom: "10px"}}>
                        <span style={{fontWeight:'bold'}}>Instance ID</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'60%'}}>
                        <Input type='text' name='instance_id' id='instance_id' onChange={this.handleFieldChange} onBlur={this.validateForm} style={{borderColor:'#b2beb5'}}></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'20%', marginTop : "10px" , marginBottom: "10px"}}>
                        <span style={{fontWeight:'bold'}}>Region</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'60%'}}>
                        <Input type='text' name='region' id='region' onChange={this.handleFieldChange} onBlur={this.validateForm} style={{borderColor:'#b2beb5'}}></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'20%', marginTop : "10px" , marginBottom: "10px"}}>
                        <span style={{fontWeight:'bold'}}>Action</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'60%'}}>
                    <Select style={{width:'100%'}} value={this.state.action}  onChange={(value) => this.setState({action: value})}>
                        <Option value="start">start</Option>
                        <Option value="stop">stop</Option>
                    </Select>
                    </Col>
                  </Row>
                  <br/>
              </div>
              <hr/>
              <div>
                  <span style={{fontWeight:'bold'}}>Description</span>
                  <br/>
                  <Row>
                     <span>
                     CRON expression have six required fields, which are separated by white space. 
                     <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://cloudplexo.com/knowledge"
                        >
                          {" "}
                          Learn more -   
                        </a>
                        about CRON expression. Enter CRON
                        expression below.

                     </span>
                  </Row>
                  <br/>
                 
              </div>
              <Row>
                    <Col style={{width:'20%', marginTop : "10px"}}>
                        <span style={{fontWeight:'bold'}}>cron_string</span>
                        <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{width:'60%'}}>
                        <Input type='text' name='cron_string' id='cron_string' onChange={this.handleFieldChange} onBlur={this.validateForm} style={{borderColor:'#b2beb5'}}></Input>
                    </Col>
                </Row>
              <hr/>
              <br/>
              <div>
                  <Row>
                  <Col style={{width:'70%'}}>
                      <Button style={{float:'right'}} onClick={()=> this.props.history.push('/rds-schedular')} >Cancel</Button>
                  </Col>
                  <Col  style={{width:'10%'}}></Col>
                   <Col style={{width:'20%'}}>
                    <Button type="primary" style={{float:'left'}} disabled={this.state.isInComplete} onClick={this.onSubmit}>Submit</Button>
                  </Col>
                  </Row>
              </div>
              <br/>
          </div>
        )
     }
}