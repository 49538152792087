import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Hub, Logger} from "@aws-amplify/core";
import {AuthService} from "../../services/auth-service";
import logo from '../../images/logo.png';
import {
    Container,
    Row,
    Col,
    Label,
    FormGroup,
    Button,
    Card,
    CardBody
  } from "reactstrap";
import {notification} from 'antd';
import FeatherIcon from "feather-icons-react";
import user03 from "../../assets/images/user/cloud-tech-bg-1.jpg";
import { AvForm, AvField } from "availity-reactstrap-validation";

export function ForgotPasswordEmailCodeForm() {
    const logger = new Logger("ForgotPasswordEmailCodeForm");
    const history = useHistory();

    // const [errorMessage, setErrorMessage] = useState("");
    // const [userNotConfirmed, setUserNotConfirmed] = useState(false);

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');
        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };

    })

    // Default handler for listening events
    const onHubCapsule = (capsule) => {
        const {channel, payload} = capsule;
        if (channel === AuthService.CHANNEL &&
            payload.event === AuthService.AUTH_EVENTS.PASSWORD_RESET_2) {
            logger.info(payload.message);
            if (!payload.success) {
                //setErrorMessage(payload.message)
                notification.open({
                    type: 'error',
                    message: "Couldn't change your password",
                    description: payload.message,
                    duration: 15
                });

                // history.push("/login");
                AuthService.login(payload.username, payload.password);

            } else {
               // setErrorMessage(null);
                history.push("/")

            }
        }
    };
    const onFinish = (event, values) => {
        logger.info('Received values of form: ', values);
        AuthService.forgotPasswordSetNew(values.email, values.code, values.password)
    };


    return     <React.Fragment>
    <section className="cover-user bg-white" style={{width:'100%'}}>
      <Container fluid className="px-0">
        <Row className="no-gutters position-relative">
          <Col lg={4} xs={{ order: 2 }} className="cover-my-30">
            <div className="cover-user-img d-flex align-items-center">
              <Row>
                <Col xs={12}>
                  <Card
                    className="login-page border-0"
                    style={{ zIndex: 1 }}
                  >
                    <CardBody className="p-0">
                    <Row style={{justifyContent: 'center'}}>
                            <img src={logo} style={{width : '150px', height : '150px'}} alt=""></img>
                        </Row>
                      <h4 className="card-title text-center">
                        Forgot password reset
                      </h4>
                      <AvForm onValidSubmit={onFinish} className="login-form mt-4">
                        <Row>
                        <Col md="12">
                            <FormGroup className="position-relative">
                              <Label for="email">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="text"
                                className="form-control pl-5"
                                name="email"
                                id="email"
                                placeholder="Enter Email"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter your email",
                                  },
                                  pattern: {
                                    value:
                                      "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                    errorMessage: "E-Mail is not valid!",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="position-relative">
                              <Label for="password">
                                Password{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="lock"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="password"
                                className="form-control pl-5"
                                name="password"
                                id="password"
                                placeholder="Enter password"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter Password",
                                  },
                                  minLength: {
                                    value: 6,
                                    errorMessage:
                                      "Your password must be between 6 and 8 characters",
                                  },
                                  maxLength: {
                                    value: 16,
                                    errorMessage:
                                      "Your password must be between 6 and 8 characters",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="position-relative">
                              <Label for="confirmpassword">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="lock"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="password"
                                className="form-control pl-5"
                                name="confirmpassword"
                                id="confirmpassword"
                                placeholder="Confirm Password"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter Password",
                                  },
                                  minLength: {
                                    value: 6,
                                    errorMessage:
                                      "Your password must be between 6 and 8 characters",
                                  },
                                  maxLength: {
                                    value: 16,
                                    errorMessage:
                                      "Your password must be between 6 and 8 characters",
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="position-relative">
                              <Label for="code">
                                Verification Code{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="lock"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <AvField style={{marginLeft:'10px'}}
                                type="text"
                                className="form-control pl-5"
                                name="code"
                                id="code"
                                placeholder="Enter Verification Code"
                                required
                                errorMessage=""
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter your verification code",
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <Button color="primary" block style={{height:'50px'}}>
                              Change Password
                            </Button>
                          </Col>
                          <div className="mx-auto">
                            <p className="mb-0 mt-3">
                              <small className="text-dark mr-2">
                                Remember your password ?
                              </small>{" "}
                              <Link
                                to="/login"
                                className="text-dark font-weight-bold"
                              >
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            lg={{ size: 8, offset: 4 }}
            xs={{ order: 1 }}
            className="padding-less img"
            style={{ backgroundImage: `url(${user03})` }}
          ></Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
    // <div style={{marginTop : 200, minHeight:'485px'}}>
    //     <Row style={{display: 'flex', justifyContent: 'center', margin: "15px"}}>
    //         <img src={logo} style={{width : '150px', height : '50px'}} alt=""></img>           
    //     </Row>

    //     <Row style={{display: 'flex', justifyContent: 'center'}}>
    //         Forgot Password (step 2)
    //     </Row>

    //     <Row>
    //         <Form style={styles.loginForm}

    //               onFinish={onFinish}
    //               onFinishFailed={onFinishFailed}
    //         >
    //             <Form.Item
    //                 name="username"
    //                 rules={[
    //                     {
    //                         required: true,
    //                         message: 'Please input your email!',
    //                     }
    //                 ]}>
    //                 <Input
    //                     prefix={<UserOutlined/>}
    //                     placeholder="Email"
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 name="password"
    //                 rules={[
    //                     {
    //                         required: true,
    //                         message: 'Please input your Password!'
    //                     }
    //                 ]}>

    //                 <Input
    //                     prefix={<LockOutlined/>}
    //                     type="password"
    //                     placeholder="Password"
    //                 />

    //             </Form.Item>
    //             <Form.Item
    //                 name="code"
    //                 rules={[
    //                     {
    //                         required: true,
    //                         message: 'Please input your Password!'
    //                     }
    //                 ]}>
    //                 <Input
    //                     prefix={<LockOutlined/>}
    //                     type="string"
    //                     placeholder="Code"
    //                 />
    //             </Form.Item>
    //             <Form.Item>

    //                 <Button type="primary" htmlType="submit" style={styles.loginFormButton}>
    //                     Change password
    //                 </Button>
    //                 Or try to <Link to="login">login</Link> </Form.Item>
    //         </Form>
    //     </Row>
    // </div>
}
